$black: #1F1F1F;
$red: #ED3237;
$orange: #23cd23;

.DriverMapModal {
  .refresh {
    margin-top: 10px;
    margin-bottom: 10px;
    background: none;
    border: none;
    color: #000000d9;
    font-size: 16px;
  }

  i {
    margin-left: 5px;
    transform: translateY(-3px);
  }
}

.BalanceHistory {
  .BalanceHistoryItem {
    padding: 15px 5px;
    border-top: 1px solid #ececec;
    margin: 15px 3px;
    -webkit-box-shadow: 0 0px 6px 1px #ececec;
    -moz-box-shadow: 0 0px 6px 1px #ececec;
    box-shadow: 0 0px 6px 1px #ececec;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;

  }

  .OrderHistoryItemHeader {
    span {
      margin-left: 5px;
      font-size: 15px;
      font-weight: 600;
    }
  }
}

.OrderHistory {
  .OrderHistoryItem {
    padding: 15px 5px;
    border-top: 1px solid #ececec;
    margin: 15px 3px;
    -webkit-box-shadow: 0 0px 6px 1px #ececec;
    -moz-box-shadow: 0 0px 6px 1px #ececec;
    box-shadow: 0 0px 6px 1px #ececec;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;

    .driver-action-row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      margin: 7px 3px;

      .driver-action-column {
        display: flex;
        flex-direction: column;
        flex-basis: 100%;
        flex: 1;

        button {
          border: none;
          font-weight: bold;
          border-radius: 5px;
          padding: 5px 15px;
          box-shadow: 0 0 5px 1px #dedede;
          background-color: #fdfdfd;

          width: 95%;
        }
      }
    }
  }

  .OrderHistoryItemHeader {

    span {
      margin-left: 5px;
      font-size: 15px;
      font-weight: 600;
    }
  }
}

.DriverMap {
  position: relative;
  width: 100%;
  height: calc(100vh - 100px);
}

.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after {
  //border-right: 3px solid $red !important;
}


.ant-breadcrumb a:hover {
  color: $red !important;
}

.AddNewButton {
  background: #ED3237;
  border-radius: 8px;
  max-width: 258px;
  //width: 100%;
  margin: 20px auto 0;
  //display: block;
  font-size: 15px;
  color: #FFFFFF;
  letter-spacing: 0;
  font-weight: 500;
  border: 1px solid $red;
  padding: 3px 10px;
  -webkit-transition: .3s all;
  -moz-transition: .3s all;
  -ms-transition: .3s all;
  -o-transition: .3s all;
  transition: .3s all;

  &:hover {
    background-color: #fff;
    color: $red;
  }
}

.TableListImg {
  width: 50px;
  cursor: pointer;
}

.DriverPage {
  //padding-top: 120px;
  //padding-left: 15px;
  //padding-right: 15px;
  padding: 15px 15px 70px;
  position: relative;
  /*width: 100%;
  padding-bottom: 40px;

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $orange;
    border-color: $orange;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: $orange;
  }

  .ant-pagination {
    .ant-pagination-item-active {
      border-color: $red;
    }
  }

  .ant-tabs-nav .ant-tabs-tab-active {
    color: $red;
  }

  .ant-tabs-nav .ant-tabs-tab:hover {
    color: $red;
  }

  .ant-tabs-ink-bar {
    background-color: $red;
  }

  .ant-input-number:hover {
    border-color: $orange;
  }

  .ant-col.ant-form-item-label {
    line-height: 18px;
    margin-top: 14px;

    > label {
      margin-bottom: 0;
    }
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-input-number-focused {
    -webkit-box-shadow: 0 0 0 2px rgba(245, 134, 52, 0.2);
    -moz-box-shadow: 0 0 0 2px rgba(245, 134, 52, 0.2);
    box-shadow: 0 0 0 2px rgba(245, 134, 52, 0.2);
  }

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: rgba(245, 134, 52, 0.1);
  }

  .ant-table-tbody {
    a {
      color: $red;
    }

    button.ant-btn-link {
      color: $red;
    }
  }*/

  .ant-tabs-bottom .ant-tabs-bottom-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 6;
  }

  .status-new {
    background-color: orange;
    display: flex;
    padding: 3px;
    border-radius: 50%;
  }

  .status-preparing {
    background-color: #4278ff;
    display: flex;
    padding: 3px;
    border-radius: 50%;
  }

  .status-ready {
    background-color: #46b500;
    display: flex;
    padding: 3px;
    border-radius: 50%;
  }

  .refresh {
    margin-top: 10px;
    margin-bottom: 10px;
    background: none;
    border: none;
    color: #000000d9;
    font-size: 16px;
    display: flex;
    align-items: center;

    > span:first-child {
      margin-right: 5px;
    }

    i {
      margin-left: 5px;
      transform: translateY(-3px);
    }

    &:hover {
      i {
        color: $red;
      }
    }
  }

  /*todo driver */
  .driverHeader {
    position: sticky;
    top: 0;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px 0;
    z-index: 5;
    border-bottom: 1px solid #ececec;
  }

  .driverMenu {
    position: sticky;
    bottom: 0;
  }

  .driverMap, .driverNotification {
    button {
      background: none;
      border: none;

      img {
        width: 26px;
      }
    }

    .NewOrderSound {
      cursor: pointer;
      width: 28px;
      height: 28px;
    }
  }

  .InActiveDriver {
    i {
      color: red;
      font-size: 18px;
      margin-left: 10px;
    }

    span {
      margin-left: 3px;
      font-size: 18px;
      font-weight: 600;
      vertical-align: middle;
    }
  }

  .DriverSettingsTab {
    border-top: 1px solid #ececec;
    padding: 15px;
    margin: 15px 3px;
    -webkit-box-shadow: 0 0px 6px 1px #ececec;
    -moz-box-shadow: 0 0px 6px 1px #ececec;
    box-shadow: 0 0px 6px 1px #ececec;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    cursor: pointer;
    height: calc(100vh - 150px);
    position: relative;

    .Active {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      font-weight: 500;
    }

    .DriverSettingsTabContent {
      overflow-y: scroll;
      height: 95%;
    }

    .DriverSettingsTabFooter {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      font-weight: 500;
      position: absolute;
      bottom: 7px;

      .LogOut {
        font-size: 16px;
        color: $red;
        line-height: 20px;
        font-weight: 700;
        cursor: pointer;
      }
    }
  }

  .NoActualOrder {
    padding: 15px 5px;
    border-top: 1px solid #ececec;
    margin: 15px 3px;
    -webkit-box-shadow: 0 0px 6px 1px #ececec;
    -moz-box-shadow: 0 0px 6px 1px #ececec;
    box-shadow: 0 0px 6px 1px #ececec;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    cursor: pointer;
  }

  .driverOrder {
    font-size: 20px;
    font-weight: 500;
  }

  .order-item-depart {
    button {
      background-color: #f87544;
      border: 1px solid #f87544;
    }
  }

  .order-item-arrive {
    button {
      background-color: #dcb420;
      border: 1px solid #dcb420;
    }
  }

  .checkLocation {
    text-align: right;
    margin-bottom: 15px;

    button {
      width: 100%;
      font-weight: 600;
      color: #fff;
      padding: 3px 15px;
      border-radius: 3px;

    }
  }

  .ant-collapse-content-box {
    p {
      margin-bottom: 10px;
      font-size: 16px;

      img {
        width: 16px;
        margin-right: 5px;
        transform: translateY(-2px);
      }

      &.branchName {
        font-size: 16px;
        font-weight: 700;
      }
    }
  }

  .ant-tabs-nav.ant-tabs-nav-animated {
    width: 100%;

    > div {
      display: flex;
      justify-content: space-between;

      > div {
        margin-right: 0;
        text-align: right;
        font-size: 18px;
      }
    }
  }

  .ant-tabs-bottom .ant-tabs-bottom-bar .ant-tabs-ink-bar {
    display: none !important;
  }

  .ant-tabs-nav .ant-tabs-tab:hover, .ant-tabs-nav .ant-tabs-tab-active {
    color: $red;
  }

  .ant-tabs-nav-container {
    padding-left: 10px;
    padding-right: 5px;
  }

  .items {
    border-top: 1px solid #ececec;

    .item {
      margin-top: 5px;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      padding-right: 10px;

      span {
        font-weight: 600;

      }
    }
  }

  .orderStateButton {
    button {
      width: 100%;
      color: #fff;
      border-radius: 4px;
      padding: 8px;
      border: none;
      font-weight: 600;
    }
  }

  .order-accept {
    button {
      background-color: green;
    }
  }

  .order-end {
    button {
      background-color: red;
    }
  }

  .clientInfo {
    position: relative;
    padding: 15px 5px;
    border-top: 1px solid #ececec;
    margin: 15px 3px;
    -webkit-box-shadow: 0 0px 6px 1px #ececec;
    -moz-box-shadow: 0 0px 6px 1px #ececec;
    box-shadow: 0 0px 6px 1px #ececec;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    cursor: pointer;

    .MarkerMessageIcon{
      position: absolute;
      right: 8px;
      top: 15px;
      cursor: pointer;
      width: 24px;
    }

    p {
      margin-bottom: 10px;
      font-size: 16px;

      img {
        width: 16px;
        margin-right: 5px;
        transform: translateY(-2px);
      }

      &.branchName {
        font-size: 16px;
        font-weight: 700;
      }
    }

    img {
      filter: saturate(0);
    }

    .message, .coin {
      color: $red;

      img {
        filter: saturate(100%);
      }
    }

    .order {
      font-size: 15px;

      span {
        font-weight: 700;
      }
    }
  }

  /*.ant-tabs.ant-tabs-bottom.ant-tabs-line {
    -webkit-box-shadow: 0 0px 6px 1px #ececec;
    -moz-box-shadow: 0 0px 6px 1px #ececec;
    box-shadow: 0 0px 6px 1px #ececec;
    padding: 10px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
  }*/
}

.rbc-day-slot > .rbc-timeslot-group .rbc-time-slot:first-child {
  border-bottom: 1px dashed #e7e7e7;
}

.rbc-day-slot .rbc-events-container {
  margin-right: 0px !important;
}

.rbc-row-bg, .rbc-row-content {
  display: none;
}

.rbc-header {
  border-bottom: none !important;
}

/*===================================
        DriverMapModal
======================================*/
.DriverMapModal.ant-modal {
  //position: absolute;
  //left: 50%;
  //-webkit-transform: translateX(-50%);
  //-moz-transform: translateX(-50%);
  //-ms-transform: translateX(-50%);
  //-o-transform: translateX(-50%);
  //transform: translateX(-50%);
  //padding-bottom: 0;
  //top: 15px;
  //background-color: #fff;
  //border-radius: 0 0 12px 12px;
  //overflow: hidden;
  max-width: calc(100vw - 30%);
  width: 100% !important;

  .ant-modal-body {
    height: calc(100vh - 18px);
  }

  @media screen and (max-width: 767px) {
    max-width: calc(100vw - 16px);
    position: relative;
    top: 20px;
    //margin: 8px auto;
    left: 0px;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    margin-bottom: 24px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
  }
}

/*===================================
        DriverFeedbackModal
======================================*/
.DriverFeedbackModal.ant-modal {
  max-width: calc(100vw - 30%);
  width: 100% !important;
  margin: 100px auto;

  .DriverFeedback {
    margin-top: 20px;

    .center {
      display: flex;
      justify-content: center;
    }

    .feedback-title {
      font-weight: 600;
      font-size: 20px;
    }
    .feedback-sub-title {
      font-weight: 600;
      font-size: 16px;
    }

    .submit-container {
      margin-top: 30px;
      display: flex;
      justify-content: flex-end;

      .disabled{
        opacity: 0.6;
        color: white;
      }

      button {
        display: inline-block;
        background: #ED3237;
        border-radius: 8px;
        width: 100%;
        height: 40px;
        border: none;
        font-weight: 600;
        font-size: 17px;
      }
    }
  }

  .ant-modal-body {
    overflow-y: scroll;
    height: calc(57vh - 18px);
  }

  @media screen and (max-width: 767px) {
    max-width: calc(100vw - 16px);
    position: relative;
    top: 20px;
    //margin: 8px auto;
    left: 0px;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    margin-bottom: 24px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
  }
}

/*===================================
        DriverAddressNotesModal
======================================*/
/*===================================
        DriverMapModal
======================================*/
.DriverAddressNotesModal.ant-modal {
  max-width: calc(100vw - 30%);
  width: 100% !important;

  .DriverAddressNotes{
    overflow-y: scroll;
  }
  .ant-modal-body {
    overflow-y: scroll;
    height: 92vh;
  }
}
/*===================================
        DriverAddressNoteModal
======================================*/
.DriverAddressNoteModal.ant-modal {
  max-width: calc(100vw - 30%);
  width: 100% !important;
  margin: 100px auto;

  .DriverAddressNote {
    margin-top: 30px;

    .submit-container {
      margin-top: 15px;
      display: flex;
      justify-content: flex-end;

      button {
        position: static;
        bottom: 24px;
        display: inline-block;
        background: #ED3237;
        border-radius: 8px;
        width: 100%;
        border: none;
        font-weight: 600;
        font-size: 17px;
      }
    }
  }

  .ant-modal-body {
    overflow-y: scroll;
    height: calc(41vh - 18px);
  }

  @media screen and (max-width: 767px) {
    max-width: calc(100vw - 16px);
    position: relative;
    top: 20px;
    //margin: 8px auto;
    left: 0px;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    margin-bottom: 24px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
  }
}

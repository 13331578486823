$black: #1F1F1F;
$red: #ED3237;
$orange: #F58634;


.Restaurant {
  padding-top: 77px;
  display: flex;
  width: 100%;
  padding-bottom: 160px;


  .LandingPanel {
    padding-top: 23px;
    padding-right: 0;
    width: calc(100% - 250px);

    .Rating {
      margin-top: 8px;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      > p {
        margin-bottom: 0;
        //margin-left: 12px;
        flex-grow: 0;
        flex-shrink: 0;

        span:last-of-type {
          margin-left: 12px;
        }
      }

      ul {
        font-size: 16px;
        -webkit-transform: translateY(-3px);
        -moz-transform: translateY(-3px);
        -ms-transform: translateY(-3px);
        -o-transform: translateY(-3px);
        transform: translateY(-3px);

        li {
          font-size: 14px;

          &.ant-rate-star-full {
            color: #F58634;
          }
        }
      }

      > span {
        font-size: 14px;
        color: #7C7C7C;
        margin-left: 12px;
        margin-right: 24px;

        b {
          color: $black;
        }

        &.Good {
          margin-left: 30px;
        }
      }
    }

    p.RestaurantBack {
      padding-left: 24px;
      font-size: 14px;
      line-height: 18px;
      color: #747474;

      img {
        -webkit-transform: rotate(180deg) translateY(2px);
        -moz-transform: rotate(180deg) translateY(2px);
        -ms-transform: rotate(180deg) translateY(2px);
        -o-transform: rotate(180deg) translateY(2px);
        transform: rotate(180deg) translateY(2px);
        height: 14px;
        margin-right: 20px;
      }

      a {
        color: #000000;

        span{
          position: relative;

          &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 1px;
            left: 0;
            bottom: -3px;
            background-color: #000;
            -webkit-transition: 0.3s all;
            -moz-transition: 0.3s all;
            -ms-transition: 0.3s all;
            -o-transition: 0.3s all;
            transition: 0.3s all;
            opacity: 0;
          }
        }

        &:hover {
          span{
            &::after{
              bottom: 0;
              opacity: 1;
            }
          }
        }
      }
    }

    .RestaurantInfo {
      padding-left: 24px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-right: 85px;
      align-items: flex-start;
    }

    .RestaurantLogo {
      display: flex;
      //flex-grow: 0;
      //flex-shrink: 0;
      margin-bottom: 30px;

      > div {
        &:last-of-type {
          margin-left: 24px;
        }
      }

      img {
        max-height: 120px;
        max-width: 120px;
        //margin-top: 10px;
        object-fit: cover;
      }

      h4 {
        font-size: 30px;
        color: $black;
        line-height: 38px;
        font-weight: 700;
        margin-bottom: 0;
      }

      .OpenTime {
        font-size: 16px;
        color: #7C7C7C;
        line-height: 20px;
        margin-bottom: 0;

        span {
          border: 1px solid #ebebeb;
          padding: 4px 10px;
          border-radius: 15px;
          font-size: 12px;
          font-weight: 600;
          color: #487b00;
        }
      }


      .LastInfo {
        margin-top: 13px;
        display: flex;

        p {
          font-weight: 700;
          font-size: 16px;
          color: $black;
          line-height: 20px;
          margin-right: 26px;
          margin-bottom: 0;

          &:last-of-type {
            margin-right: 0;
          }

          span {
            font-size: 12px;
            color: #7C7C7C;
            font-weight: 600;
            line-height: 15px;

          }

          .PanelFreeDelivery {
            margin-top: 3px;
            display: inline-block;
            background: #F58634;
            border-radius: 6px;
            padding: 4px 6px;
            font-size: 12px;
            color: #FFFFFF;
            font-weight: 700;
            text-transform: uppercase;
            width: 70px;
            text-align: center;
          }
        }
      }
    }

    .RestaurantMap {
      max-width: 328px;
      width: 350px;
      flex-grow: 0;
      flex-shrink: 0;

      > div {
        width: 100%;
        height: 200px;
        background-color: #DDDDDD;
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        border-radius: 6px;
        overflow: hidden;
        margin-bottom: 10px;
        position: relative;
      }

      p {
        color: #7C7C7C;

        &.AddressLine {
          line-height: 20px;
          font-size: 16px;
          width: 100%;
          -ms-text-overflow: ellipsis;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }


    .RestaurantTabs {
      margin-top: 10px;

      .ant-tabs-tabpane {
        padding-left: 50px;
      }

      .ant-tabs-bar {
        margin-bottom: 30px;
      }

      .ant-tabs-nav-scroll {
        padding-left: 50px;

        .ant-tabs-tab {
          font-size: 16px;
          color: #747474;
          font-weight: 700;

          &.ant-tabs-tab-active {
            color: $red;
          }
        }

        .ant-tabs-ink-bar {
          background-color: $red;
          height: 3px;
        }

        .ant-tabs-nav .ant-tabs-tab:hover {
          color: $red;
        }
      }


      .PopularPanel {
        padding-bottom: 30px;
        padding-right: 90px;
        position: relative;

        &:not(:last-of-type)::after {
          content: '';
          position: absolute;
          width: calc(100% - 90px);
          left: 0;
          bottom: 0;
          height: 1px;
          background-color: #DDDDDD;
        }
      }

      .PanelTitle {
        position: relative;
        margin-top: 30px;
        margin-bottom: 52px;

        h5 {
          font-size: 24px;
          color: #1B1B1B;
          line-height: 31px;
          font-weight: 700;
          margin-bottom: 0;
        }

        &::after {
          content: '';
          position: absolute;
          width: 94px;
          height: 4px;
          background: #E85D42;
          left: 0;
          bottom: -20px;
        }
      }

      .MyFavorite {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }

      .RectangleItem {
        cursor: pointer;
        margin-bottom: 30px;
        min-width: 250px;

        img {
          display: block;
          width: 240px;
          border-radius: 12px;
          height: 150px;
          object-fit: cover;
        }

        p {
          max-width: 240px;
          margin-top: 8px;
          margin-bottom: 0;
          font-size: 16px;
          color: #1F1F1F;
          line-height: 20px;
          font-weight: 600;
        }

        span {
          display: block;
          font-size: 14px;
          color: #7C7C7C;
          line-height: 18px;

          &.PanelMin {
            margin-top: 4px;
            font-size: 14px;
            color: #1f1f1f;
            line-height: 18px;
            font-weight: 600;

            span {
              display: inline;
              font-weight: 400;
              color: #7C7C7C;
            }
          }

          &.PanelDelivery {
            margin-top: 4px;
          }
        }
      }

      .RestaurantReviews {
        padding-left: 15px;
        padding-right: 15px;

        h4 {
          font-size: 24px;
          color: $black;
          line-height: 31px;
          font-weight: 700;
        }

        .AllReviews {
          padding-bottom: 30px;

          > ul {
            padding-left: 25px;
            margin-top: 30px;
            padding-top: 30px;
            position: relative;
            padding-right: 25px;

            &::after {
              content: '';
              position: absolute;
              width: calc(100% - 0px);
              left: 0;
              top: 0;
              height: 1px;
              background-color: #DDDDDD;
            }

            > li {
              >ul{
                margin-left: 40px;
                li {
                  max-width: 760px;
                  margin-bottom: 30px;

                  > p {
                    font-size: 14px;
                    color: $black;
                    line-height: 18px;
                    margin-top: 12px;
                  }

                  > div {
                    display: flex;

                    .user {
                      margin-right: 13px;
                      width: 40px;
                      height: 40px;
                      flex-shrink: 0;
                      flex-grow: 0;

                      img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                      }

                      span {
                        text-transform: uppercase;
                        display: flex;
                        width: 100%;
                        height: 100%;
                        //background-color: #00E3AA;
                        justify-content: center;
                        align-items: center;
                        -webkit-border-radius: 50%;
                        -moz-border-radius: 50%;
                        border-radius: 50%;
                        font-weight: 600;
                        font-size: 16px;
                        color: #FFFFFF;
                      }
                    }

                    .ReviewName {
                      p {
                        text-transform: capitalize;
                        margin-bottom: 0;
                        font-size: 16px;
                        color: $black;
                        line-height: 19px;
                        font-weight: 700;

                        span {
                          margin-left: 12px;
                          font-size: 14px;
                          color: #7C7C7C;
                          line-height: 18px;
                          font-weight: 400;
                        }
                      }

                      ul {
                        font-size: 14px;

                        li {
                          &.ant-rate-star-full {
                            color: #F58634;
                          }
                        }
                      }
                    }
                  }
                }
              }
              max-width: 760px;
              margin-bottom: 30px;

              > p {
                font-size: 14px;
                color: $black;
                line-height: 18px;
                margin-top: 12px;
              }

              > div {
                display: flex;

                .user {
                  margin-right: 13px;
                  width: 40px;
                  height: 40px;
                  flex-shrink: 0;
                  flex-grow: 0;

                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }

                  span {
                    text-transform: uppercase;
                    display: flex;
                    width: 100%;
                    height: 100%;
                    //background-color: #00E3AA;
                    justify-content: center;
                    align-items: center;
                    -webkit-border-radius: 50%;
                    -moz-border-radius: 50%;
                    border-radius: 50%;
                    font-weight: 600;
                    font-size: 16px;
                    color: #FFFFFF;
                  }
                }

                .ReviewName {
                  p {
                    text-transform: capitalize;
                    margin-bottom: 0;
                    font-size: 16px;
                    color: $black;
                    line-height: 19px;
                    font-weight: 700;

                    span {
                      margin-left: 12px;
                      font-size: 14px;
                      color: #7C7C7C;
                      line-height: 18px;
                      font-weight: 400;
                    }
                  }

                  ul {
                    font-size: 14px;

                    li {
                      &.ant-rate-star-full {
                        color: #F58634;
                      }
                    }
                  }
                }
              }
            }
          }

          > div {
            button {
              margin-top: 40px;
              border-radius: 8px;
              font-size: 14px;
              background: #ED3237;
              color: #FFFFFF;
              border: 1px solid #ED3237;
              font-weight: 600;
              text-align: center;
              display: block;
              //margin-left: auto;
              padding: 8px;
              max-width: 180px;
              width: 100%;
              transition: 0.3s all;

              &:hover {
                background-color: #fff;
                color: #ED3237;
              }
            }
          }
        }

        .AddNewReview {

          position: relative;
          margin-top: 20px;
          padding-top: 30px;

          .ant-row.ant-form-item {
            margin-bottom: 0;
          }

          > p {
            //text-align: center;
            font-size: 16px;
          }

          .SettingsUpdateButton {
            margin-top: 15px;

            button {
              background-color: $orange;
              border-color: $orange;

              &:hover {
                background-color: #fff;
                color: $orange;
              }
            }
          }

          li {
            &.ant-rate-star-full {
              color: #F58634 !important;
            }
          }

          &::after {
            content: '';
            position: absolute;
            width: calc(100% - 0px);
            left: 0;
            top: 0;
            height: 1px;
            background-color: #DDDDDD;
          }

          .restaurantReviewTextarea {
            max-width: 600px;
          }
        }
      }
    }

    @media screen and (max-width: 767px) {
      width: 100%;

      .RestaurantTabs {
        .ant-tabs-tabpane {
          padding-left: 0px;
          padding-right: 0px;

          .PopularPanel {
            padding-right: 0;

            &::after {
              width: calc(100% - 0px);
              left: 0px;
            }

            .PanelTitle {
              //text-align: center;
              padding-left: 15px;

              &::after {
                left: 15px
                //-webkit-transform: translateX(-50%);
                //-moz-transform: translateX(-50%);
                //-ms-transform: translateX(-50%);
                //-o-transform: translateX(-50%);
                //transform: translateX(-50%);
              }
            }

            //.MyFavorite {
            //  justify-content: center;

            .RectangleItem {
              cursor: pointer;
              //text-align: center;
              width: 100%;
              flex-grow: 0;
              flex-shrink: 0;
              padding-left: 15px;

              img {
                width: calc(100% - 15px);
                height: 200px;
              }
            }

            //}
          }
        }

        .AllReviews {
          ul {
            &::after {
              width: 100% !important;
            }
          }
        }
      }
    }

    @media screen and (max-width: 575px) {
      width: 100%;

      .RestaurantTabs {
        .AllReviews {
          ul {
            padding-left: 10px !important;
            padding-right: 10px !important;

            .ReviewName {
              span {
                display: block;
                margin-left: 0 !important;
              }

              .ant-rate {
                padding-left: 0 !important;
              }
            }
          }
        }
      }
    }

    @media screen and (max-width: 575px) {
      .RestaurantInfo {
        padding-right: 15px;
        padding-left: 15px;
      }
      .RestaurantLogo {
        flex-wrap: wrap;
        >div{
          margin-left: 0 !important;
        }
        +div{
          width: 100%;
        }
      }
      .RestaurantMap {
        max-width: none;

        > div {
          height: 160px;
          position: relative;
        }
      }
      .Rating {
        > p {
          margin-top: 10px;

          span {
            margin-left: 0 !important;
            width: 100%;
            display: block;
          }
        }
      }
    }

  }

  @media screen and (max-width: 767px) {
    padding-bottom: 40px;
    .ant-tabs-nav-scroll {
      padding-left: 0 !important;
      text-align: center;
    }
  }
}


/* todo Custom change password modal */
.CustomChangePassword {
  .ant-modal-body {
    padding: 30px 40px 28px;
  }

  .ChangePasswordTitle {
    font-size: 30px;
    color: #1f1f1f;
    line-height: 41px;
    font-weight: 700;
    margin-bottom: 55px;
  }

  .GlobalInput {
    p {
      font-size: 14px;
    }

    input {
      height: 56px;
    }
  }

  form {
    button {
      background: #ED3237;
      border-radius: 8px;
      max-width: 258px;
      width: 100%;
      margin: 20px auto 0;
      display: block;
      font-size: 16px;
      color: #FFFFFF;
      letter-spacing: 0;
      font-weight: 700;
      border: 1px solid $red;
      padding: 14px 0;
      -webkit-transition: .3s all;
      -moz-transition: .3s all;
      -ms-transition: .3s all;
      -o-transition: .3s all;
      transition: .3s all;

      &:hover {
        background-color: #fff;
        color: $red;
      }
    }
  }
}

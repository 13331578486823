.Pwu {
  header {
    background-position: left center;
  }

  padding-bottom: 170px;
  @media screen and (max-width: 767px) {
    padding-bottom: 70px;
  }

  header {
    position: relative;

    &::after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-color: rgba(255, 255, 255, 0.75);
      z-index: 0;
    }

    > * {
      position: relative;
      z-index: 3;
    }
  }
}

.WhoWe {
  padding-top: 58px;

  h1 {
    font-size: 56px;
    color: #1F1F1F;
    font-weight: 700;
    line-height: 70px;
    position: relative;
    margin-bottom: 16px;
    display: inline-block;

    img {
      position: absolute;
      bottom: 35px;
      z-index: -1;
      left: -48px;
      height: 51px;
    }

    & + p {
      font-size: 16px;
      color: #1F1F1F;
      text-align: center;
      line-height: 29px;
    }
  }

  @media screen and (max-width: 575px) {
    h1 {
      font-size: 40px;

      & + p {
        text-align: justify;
      }
    }
  }
}

.WhoWe, .WhoWe::after, .WhoWe::before {
  content: '';
  display: block;
  clear: both;
}

.WeDeliver {
  h3 {
    padding-top: 140px;
    font-size: 56px;
    color: #1F1F1F;
    font-weight: 700;
    line-height: 70px;
    position: relative;
    margin-bottom: 16px;
    display: inline-block;

    img {
      position: absolute;
      bottom: 35px;
      z-index: -1;
      left: -48px;
      height: 51px;
    }

    & + p {
      font-size: 16px;
      color: #1F1F1F;
      line-height: 29px;
    }
  }

  @media screen and (max-width: 575px) {
    text-align: center;
    h3 {
      font-size: 40px;
      text-align: center;

      & + p {
        text-align: justify;
      }
    }
  }
}

.PartnerUsImg {
  margin-top: 76px;

  img {
    height: 700px;
    width: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 767px) {
    margin-top: 20px;
    img {
      height: 300px;
    }
  }
}

.PartnerUsBg {
  position: relative;

  &::after {
    position: absolute;
    z-index: -1;
    content: '';
    width: 361px;
    height: 323px;
    background-image: url('../../img/ic_patner_with_us.svg');
    filter: saturate(0);
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    bottom: 0;
    left: 0;
  }

  @media screen and (max-width: 767px) {
    &::after {
      content: none;
    }
  }
}

.refoundPopUp{
  width: 100%;
  height: 100%;
  display:flex;
  justify-content: center;
  align-items: center;
  background: rgba(175, 175, 175, 0.5);
  position: fixed;
  top: 0;
  left: 0;
}
.refoundPopUp>div{
  width: 320px;
  height: auto;
  padding: 20px;
  display: grid;
  background: #FFFFFF;
  border-radius: 10px;
}
.refoundPopUp>div>div{
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}




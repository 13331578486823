$black: #1F1F1F;
$red: #ED3237;
$orange: #F58634;

body, html {
  font-family: 'Source Sans Pro', sans-serif !important;

  //overflow: hidden;
}

.rel {
  position: relative;
  background-color: red;
  height: calc(100% + 160px);
}
.AddBugPartner{
  margin-top: 10px;
  button{
    border-radius: 8px;
    font-size: 14px;
    background: #ED3237;
    color: #FFFFFF;
    border: 1px solid #ED3237;
    font-weight: 600;
    text-align: center;
    display: block;
    margin-left: auto;
    padding: 8px;
    width: 100%;
    transition: 0.3s all;
  }
}
.ant-modal {
  @media screen and (max-width: 767px) {
    max-width: calc(100vw - 16px) !important;
  }
}

/**
 * Hover
 */
.hoverable {
  cursor: pointer;
  -webkit-transition: 0.2s all;
  -moz-transition: 0.2s all;
  -ms-transition: 0.2s all;
  -o-transition: 0.2s all;
  transition: 0.2s all;

  &:hover {
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05);
  }
}


// END Hover


::selection {
  background: $red;
}

button:focus {
  outline: none !important;
}

ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0 !important;
}

a {
  &:hover {
    text-decoration: none;
  }
}

.ant-input:hover, .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: $orange !important;
}

.ant-input:focus {
  border-color: $orange !important;
}

.ant-input:focus {
  -webkit-box-shadow: 0 0 0 2px rgba(245, 134, 52, 0.2);
  -moz-box-shadow: 0 0 0 2px rgba(245, 134, 52, 0.2);
  box-shadow: 0 0 0 2px rgba(245, 134, 52, 0.2);
}


.ant-checkbox-inner {
  -webkit-box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.18);
  -moz-box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.18);
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.18);
  border: 1px solid #BCC3CC;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.ant-radio-checked .ant-radio-inner {
  border-color: $orange;
}

.ant-radio-checked::after {
  border-color: $orange;
}

.ant-radio-inner {
  &::after {
    background-color: $orange;
  }
}

.ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
  border-color: $orange;
}

.Uynushka {
  text-align: right;
}

@media screen and (max-width: 767px) {
  .ant-modal,
  .CustomBasketModal,
  .CustomNotificationModal,
  .ShowImg {
    top: 0 !important;
    padding-bottom: 0 !important;
    margin-bottom: 8px !important;
  }
  .Modal-wrap {
    padding-bottom: 0 !important;
  }
  .ant-modal-body {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

nav.Nav {
  position: fixed;
  width: 100%;
  //box-shadow: 0 0 10px 2px #0000001f;
  display: flex;
  justify-content: space-between;
  padding: 14px 30px;
  padding-right: 70px;
  align-items: center;
  z-index: 6;
  left: 0;
  top: 0;
  &.dodo {
    box-shadow: 0 0 3px 3.5px #d8d8d880 !important;
    background-color: #fff !important;

    .SearchFood {
      li {
        input {
          background: #F3F3F3 !important;
        }
      }
    }
  }

  /*================================================
          nav scrolled class
  ================================================*/
  .MobileMenu {
    position: absolute;
    left: 16px;
    top: 23px;
    width: 23px;
    height: 20px;
    z-index: 3;
    cursor: pointer;

    &.active {
      span {
        background-color: transparent;

        &::after, &::before {
          top: 0;
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          -o-transform: rotate(45deg);
          transform: rotate(45deg);
          background-color: $red;
        }

        &::before {
          -webkit-transform: rotate(135deg);
          -moz-transform: rotate(135deg);
          -ms-transform: rotate(135deg);
          -o-transform: rotate(135deg);
          transform: rotate(135deg);
        }
      }
    }

    span {
      pointer-events: none;
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: $orange;
      top: calc(50% - 1px);
      left: 0;
      -webkit-transition: 0.3s all;
      -moz-transition: 0.3s all;
      -ms-transition: 0.3s all;
      -o-transition: 0.3s all;
      transition: 0.3s all;

      &::after, &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: $orange;
        left: 0;
        top: 7px;
        -webkit-transition: inherit;
        -moz-transition: inherit;
        -ms-transition: inherit;
        -o-transition: inherit;
        transition: inherit;
      }

      &::before {
        top: -7px;
      }
    }
  }

  &.scrolled {
    background-color: #fff;
    box-shadow: 0 0 3px 3.5px #d8d8d880;

    ul {
      &.SearchFood {
        li {
          position: relative;

          input {
            background: #F3F3F3 !important;
          }
        }
      }
    }
  }

  /*++++++++++*/

  ul {
    &.Logo {
      position: absolute;
      left: 50%;
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      -o-transform: translateX(-50%);
      transform: translateX(-50%);

      img {
        width: 90px;
      }
    }

    &.SearchFood {
      max-width: 290px;
      width: 100%;

      li {
        position: relative;

        ul {
          position: absolute;
          top: 100%;
          left: 0;
          background-color: #fff;
          border-radius: 0 0 12px 12px;
          width: 100%;
          border: 1px solid rgba(151, 151, 151, 0.2);

          li {
            font-weight: 600;
            border-bottom: 1px solid #EDEDED;
            padding: 16px 5px 16px 60px;
            font-size: 16px;
            color: #1B1B1B;
            letter-spacing: 0;
            cursor: pointer;
            -webkit-transition: 0.3s all;
            -moz-transition: 0.3s all;
            -ms-transition: 0.3s all;
            -o-transition: 0.3s all;
            transition: 0.3s all;
            position: relative;
            -ms-text-overflow: ellipsis;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;

            img {
              width: 40px;
              position: absolute;
              top: 50%;
              left: 10px;
              -webkit-transform: translateY(-50%);
              -moz-transform: translateY(-50%);
              -ms-transform: translateY(-50%);
              -o-transform: translateY(-50%);
              transform: translateY(-50%);
            }

            &:last-of-type {
              border-bottom: none;
            }

            &:hover {
              color: $red;
            }

          }
        }

        .SearchClose {
          cursor: pointer;
          //pointer-events: none;
          position: absolute;
          width: 16px;
          left: 20px;
          top: 50%;
          -webkit-transform: translateY(-50%);
          -moz-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          -o-transform: translateY(-50%);
          transform: translateY(-50%);
        }

        input {
          background: #FFFFFF;
          border-radius: 12px;
          font-size: 16px;
          color: #2E2E2E;
          text-align: left;
          line-height: 20px;
          padding: 14px 15px 14px 60px;
          border: none;
          width: 100%;

          &::placeholder {

            color: #7C7C7C;
          }

          &:focus {
            outline: none;
            background-color: #F3F3F3;
            -webkit-border-radius: 12px 12px 0 0;
            -moz-border-radius: 12px 12px 0 0;
            border-radius: 12px 12px 0 0;

          }
        }
      }
    }
  }

  .Menu {

    color: $black;
    display: flex;
    align-items: center;

    li {
      margin-right: 30px;
      cursor: pointer;
      text-transform: uppercase;
      color: $black;
      font-size: 15px;
      font-weight: 600;
      position: relative;

      img {
        height: 30px;
        cursor: pointer;
      }

      a {
        color: $black;

        &:hover {
          text-decoration: none;
        }
      }

      &.CartIcon {
        margin-right: 30px;
      }

      &:last-of-type {
        margin-right: 0;
      }

      &.MenuSignUp {
        color: $red;
      }

      &.UserAccountLink {
        a {
          color: $red;
        }
        .userDropdown {
          position: absolute;
          top: 100%;
          left: 0;
          right: 0;
          padding-top: 5px;
          text-align: center;
          background-color: #fff;
          box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.1);
          border-radius: 5px;
          &:hover {
            cursor: default;
          }
          ul {
            li {
              margin-right: 0;
              padding-top: 5px;
              padding-bottom: 5px;
              &:hover {
                cursor: default;
                background-color: #f7f7f7;
              }
              a {
                color: #747474;
              }
              &:last-child {
                a {
                  color: $red;
                  font-size: 16px;
                  line-height: 20px;
                  font-weight: 700;
                }
              }
              }
            }
          }
        }

      @media screen and (min-width: 1200px) {
        &:not(.hoverable):not(.userDropdownLi)::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 2px;
          background-color: $red;
          left: 0;
          bottom: -5px;
          opacity: 0;
          -webkit-transition: 0.3s all;
          -moz-transition: 0.3s all;
          -ms-transition: 0.3s all;
          -o-transition: 0.3s all;
          transition: 0.3s all;
        }
        &:not(.hoverable):not(.userDropdownLi):hover::after {
          opacity: 1;
          bottom: -2px;
        }
      }
    }
  }

  .NotCount {
    position: relative;

    &::after {
      position: absolute;
      content: attr(data-count);
      width: 21px;
      height: 21px;
      background-color: $orange;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      //border: 1px solid #fff;
      font-size: 13px;
      color: #FFFFFF;
      font-weight: 700;
      left: 19px;
      bottom: 16px;
      text-align: center;
    }

    @media screen and (max-width: 1200px) {
      position: absolute;
      right: 22px;
      z-index: 4;
      img {
        width: 26px;
      }
      &::after {
        width: 18px;
        height: 18px;
        font-size: 12px;
        left: 15px;
        bottom: 12px;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    padding: 23px 15px;


    ul.SearchFood {
      position: relative;
      max-width: none;

      li {
        .SearchClose {
          cursor: pointer;
          width: 20px;
          right: 45px;
          left: auto;
          pointer-events: auto;

          g {
            g {
              stroke: #444444;
            }
          }
        }

        input {
          width: 0;
          background-color: #F3F3F3 !important;
          padding: 10px 0 10px;
          -webkit-transition: 0.2s all;
          -moz-transition: 0.2s all;
          -ms-transition: 0.2s all;
          -o-transition: 0.2s all;
          transition: 0.2s all;
        }
      }

      &.open {
        z-index: 4;

        li {
          input {
            width: 100%;
            padding-left: 50px;
          }

          .SearchClose {
            cursor: pointer;
            left: 16px;
            right: auto;
            width: 14px;

            g {
              g {
                stroke: #444444;
              }
            }
          }
        }
      }
    }
    .Menu {
      position: absolute;
      background-color: #fff;
      width: 100%;
      text-align: center;
      display: flex;
      flex-direction: column;
      top: calc(100% - 2px);
      //align-items: flex-end;
      left: 0;
      padding: 40px 30px 30px 30px;
      //padding-right: 60px;
      //box-shadow: 0 2px 0px 1.5px #d8d8d880;
      -webkit-box-shadow: -1px 2px 0px 1.5px #d8d8d8;
      -moz-box-shadow: -1px 2px 0px 1.5px #d8d8d8;
      box-shadow: -1px 2px 0px 1.5px #d8d8d8;

      li {
        margin-bottom: 15px;
        display: inline-block;
        margin-left: 0 !important;
        margin-right: 0 !important;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      .SocialUl {
        margin-top: 15px;
        display: flex;

        li {
          margin-left: 10px !important;
          margin-right: 10px !important;

          img {
            height: 40px;
          }
        }
      }
    }
    ul.Logo {
      img {
        width: 70px;
      }
    }
  }
}

.vehicleInformation {
  input {
    padding-right: 34px;
  }

  img {
    position: absolute;
    bottom: 20px;
    right: 155px;
    width: 20px;
  }

  &:hover .verifyImg {
    display: none;
  }

  &:hover img.cancelImg {
    display: block;
  }

  img.cancelImg {
    display: none;
    cursor: pointer;
  }

}


footer {
  border-top: 1px solid #EDEDED;
  //margin-top: 160px;
  @media screen and (max-width: 767px) {
    text-align: center;
    ul, div {
      justify-content: center;
      align-items: center !important;
    }
  }
  .footer {
    max-width: 1190px;
    width: 100%;
    margin: 0 auto;
    padding: 50px 15px;
    position: relative;
  }

  h4 {
    margin-top: 10px;
    font-size: 24px;
    color: $black;
    letter-spacing: 0;
  }

  h5 {
    font-weight: 600;
    font-size: 14px;
  }

  .Socials {
    ul {
      margin-top: 60px;
      display: flex;
    }

    li {
      margin-right: 20px;

      img {
        width: 42px;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }

    @media screen and (max-width: 767px) {
      margin-bottom: 30px;
      h4 {
        margin-top: 0;
      }
      ul {
        margin-top: 15px;
      }
    }
  }

  .GetAround {
    position: relative;

    > ul {
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
    }

    li {
      line-height: 14px;
      font-size: 12px;
      color: #7C7C7C;
      cursor: pointer;
      display: inline;

      &:hover {
        text-decoration: underline;
      }

      a {
        font-size: 12px;
        color: #7C7C7C;
      }
    }

    img {
      position: absolute;
      width: 275px;
      left: 90px;
      bottom: -178px;
      z-index: -2;
    }

    @media screen and (max-width: 767px) {
      position: static;
      margin-bottom: 30px;
      img {
        left: auto;
        bottom: 0;
        right: 15px;
      }
      li {
        font-size: 14px;
        margin-bottom: 5px;

        a {
          font-size: 14px;
        }
      }
    }
  }

  .Newsletter {
    position: relative;

    p {
      font-size: 12px;
      color: #7C7C7C;
      margin-bottom: 8px;
    }

    img {
      position: absolute;
      width: 186px;
      left: 180px;
      bottom: -165px;
      z-index: -12;
      @media screen and (min-width: 1370px) {
        left: 280px;
      }
      @media screen and (max-width: 1200px) {
        display: none;
      }
    }

    input {
      max-width: 270px;
      width: 100%;
      padding: 8px 15px;
      font-size: 12px;
      line-height: 15px;
      color: #2E2E2E;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 3px 0 0 3px;
      border: 1px solid #d2d2d2;

      &::placeholder {
        color: #9e9e9e;
      }

      &:focus {
        outline: none;
      }

    }

    button {
      background: $red;
      border-radius: 0 3px 3px 0;
      border: none;
      line-height: 15px;
      color: #ffffff;
      font-weight: 600;
      padding: 10px 13px;
      font-size: 12px;
    }

    & > div {
      display: flex;
    }

    @media screen and (max-width: 767px) {
      p {
        font-size: 14px;
      }
    }
  }

  .Copyright {
    padding: 25px 0;
    background-color: $orange;

    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 1190px;
      padding-left: 15px;
      padding-right: 15px;
      width: 100%;
      margin: 0 auto;
    }

    p {
      font-size: 14px;
      color: #FFFFFF;
      letter-spacing: 0;
      margin-bottom: 0;
    }

    ul {
      display: flex;
      align-items: center;

      li:not(:first-of-type) {
        margin-left: 40px;
      }

      li {
        a {
          font-size: 14px;
          color: #FFFFFF;
          letter-spacing: 0;
          text-decoration: underline;
        }
      }
    }

    @media screen and (max-width: 575px) {
      > div {
        display: block;
        text-align: center;

        ul {
          justify-content: center;
        }
      }
    }
  }
}


/*==========================
      header
==========================*/

header {
  height: 480px;
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  @media screen and (max-width: 575px) {
    height: 350px;
  }
}

.open_branch{
  font-weight: bold;
  position: relative;
  a{
    color: #000000;
    text-decoration: none;
  }
  &:after{
    content: ''!important;
    position: absolute!important;
    left: -20px!important;
    top: 5px!important;
    width: 15px!important;
    height: 15px!important;
    background-color: green!important;
    border-radius: 50%!important;
    opacity: 1!important;
  }
}

.close_branch{
  font-weight: bold;
  position: relative;
  a{
    color: #000000;
    text-decoration: none;
  }
  &:after{
    content: '' !important;
    position: absolute !important;
    left: -20px !important;
    top: 5px !important;
    width: 15px !important;
    height: 15px !important;
    background-color: red !important;
    border-radius: 50% !important;
    opacity: 1!important;
  }
}

.getFoodType {
  > div {
    display: flex;
    justify-content: space-between;
    padding: 0 5px;
    @media screen and (max-width: 520px) {
     display: block;
      >div {
        display: flex;
        justify-content: space-around;
        padding: 0 10px;
        margin: 0 0 10px 0 !important;
        button {
          font-size: 14px;
          margin: 0 !important;
        }
      }
    }
    >div {
      margin-bottom: 10px;
      button {
        border: none;
        font-weight: bold;
        border-radius: 5px;
        padding: 5px 15px;
        box-shadow: 0 0 5px 1px #dedede;
        background-color: #fdfdfd;

        &.active {
          color: $orange;
          border-bottom: 3px solid $orange;
        }
      }
    }
  }
}

/*==============================
          address
===============================*/

.HomeSearchComponent {
  position: absolute;
  left: 50%;
  top: 50%;
  max-width: 680px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  .active {
    color: red;
    //text-decoration: underline;
  }

  h2 {
    text-align: center;
    font-size: 56px;
    color: $black;
    font-weight: 700;
    line-height: 70px;
  }

  .getFoodType {
    .getFoodTypeSelects{

    .when, .whenTime{
      //max-width: 140px;
      margin-left: 15px;
      display: flex;
      margin-top: 4px;
      >div{
        position: relative;
        &::after{
          content: '';
          position: absolute;
          border: 5px solid ;
          border-bottom-width: 0;
          border-color: #fff transparent;
          right: 5px;
          top: 13px;
        }
      }
      select{
        -webkit-appearance:none;
        width: auto;
        //width: 100%;
        padding: 2px 20px 2px 10px;
        //border: 1px solid rgba(0, 0, 0, 0.49);
        border: none;
        background-color: $red;
        color: #fff;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        cursor: pointer;
        font-weight: bold;
        &:focus {
          outline: none;
        }
        option{
          background-color: #fff;
          color: #000;
          padding: 3px;
        }
      }
      p{
        margin-bottom: 0;
        //color: $orange;
        font-weight: bold;
      }
    }
      .whenTime {
        div {
          padding-left: 15px;
        }
      }
    }

    & :first-of-type{
      & :not(:first-child) {
        margin-left: 15px;
      };
    }
  }

  .getFoodButton {
    position: relative;

    > button {
      position: absolute;
      top: 1px;
      right: 0;
      height: calc(100% - 1px);
      padding-left: 35px;
      padding-right: 35px;
      background: $red;
      border-radius: 0 16px 16px 0;
      font-size: 18px;
      color: #FFFFFF;
      font-weight: 600;
      border: none;

      &:hover {
        background-color: #d8232a;
      }

      &:focus {
        outline: none;
      }

      img {
        height: 30px;
        width: 30px;
        margin: 0;
        @media screen and (max-width: 700px){
          height: 24px;
        }
      }
    }
  }

  & > div {
    width: 100%;
    margin-top: 50px;
    position: relative;

    span {
      position: absolute;
      left: 20px;
      top: 0;
      height: 100%;
      cursor: pointer;

      > img {
        margin-top: 15px;
        width: 24px;
        cursor: pointer;
      }

      &:hover img {
        -webkit-animation: bounce 1s infinite;
        -o-animation: bounce 1s infinite;
        animation: bounce 1s infinite;
      }
    }

    input {
      width: 100%;
      padding: 16px 150px 16px 60px;
      background: #FFFFFF;
      border: 1px solid #EFEFEF;
      border-radius: 16px;
      line-height: 23px;
      font-size: 18px;
      color: #747474;

      &:focus {
        outline: none;
      }
    }


  }

  @media screen and (max-width: 700px) {
    h2 {
      font-size: 26px;
      line-height: 40px;
    }

    .getFoodType{
      display: block;
      text-align: center;
      .getFoodTypeSelects{
        display: block;
        margin: auto;
        .when,
        .whenTime{
          padding-bottom: 10px;
          display: grid;
          grid-template-columns: 50px 120px;
          width: 50%;
          margin: 0 auto;
          max-width: fit-content;
          select{
            width: 100%;
          }
        }
      }
    }

    .getFoodButton {
      button {
        padding-left: 15px;
        padding-right: 15px;
        font-size: 13px;
      }
    }

    > div {
      margin-top: 30px;

      input {
        padding: 10px 100px 10px 40px;
        font-size: 13px;
      }


      span {
        left: 10px;

        > img {
          margin-top: 10px;
        }

        &:hover img {
          -webkit-animation: bounce 1s infinite;
          -o-animation: bounce 1s infinite;
          animation: bounce 1s infinite;
        }
      }
    }
  }
  @media only screen and (max-width: 767px)  {
    max-width: none;
  }
}

.deleteBasketItemModal {
  .ant-btn {
    height: auto;
    font-size: 12px;
    letter-spacing: 0.38px;
    padding: 8px 33px;
    border: none;
    opacity: 0.6;

    &:hover {
      color: $red;
    }
  }

  .ant-btn-showButtonOk {
    background-color: $red;
    opacity: 1;
    color: #fff;
    border: 1px solid $red;

    &:hover {
      color: $red;
      background-color: #fff;
    }
  }
}

/*================================
        Modals
================================*/
.Modal-wrap {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-bottom: 24px;
  z-index: 12;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.65);
}

.Modal-close {
  position: absolute;
  right: 35px;
  line-height: 14px;
  top: 35px;
  border: none;
  background-color: transparent;
  font-size: 20px;
  width: 16px;
  height: 16px;
  background-image: url('../../img/ic_close.svg');
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;

  i {
    display: none;
  }
}

/*===================================
        CustomNotificationModal
======================================*/
.CustomNotificationModal {
  max-width: 464px;
  position: absolute;
  right: 55px;
  top: 0;
  background-color: #fff;
  border-radius: 0 0 12px 12px;
  overflow: hidden;

  ul {
    padding-bottom: 25px;

    li {
      padding: 16px 35px 20px;
      border-bottom: 1px solid #EDEDED;
      position: relative;

      img {
        position: absolute;
        right: 35px;
        top: 50%;
        height: 42px;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
      }

      &:last-of-type {
        border: none;
      }
    }
  }

  .NotificationTitle {
    padding: 35px;
    margin-bottom: 0;
    font-size: 18px;
    color: $black;
    line-height: 23px;
    padding-bottom: 30px;
    border-bottom: 1px solid #EDEDED;
  }

  .NotificationTime {
    font-size: 12px;
    color: #747474;
    line-height: 15px;
    margin-bottom: 6px;
    display: inline-block;
  }

  .NotificationDescription {
    font-size: 14px;
    color: #1B1B1B;
    font-weight: 600;
    line-height: 18px;
    position: relative;
    z-index: 4;
    margin: 0;
  }

  .NotificationAccept {
    p {
      span {
        color: $red;
      }
    }
  }

  @media screen and (max-width: 767px) {
    max-width: calc(100vw - 16px) !important;
    position: relative;
    top: 100px;
    margin: 8px auto;
    left: 0;
    margin-bottom: 24px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    //left: 50%;
  }
  .Modal-close-x {
    display: none;
  }
  .notFound {
    div {
      text-align: center;
      padding: 50px;
      p {
        margin: 1.5rem;
      }
    }
  }
}

.CardTotalMinimum {
  color: #737373;
  font-size: 12px;
  text-align: center;
  cursor: default;
}

/*===================================
        CustomBasketModal
======================================*/
.CustomBasketModal {
  max-width: 464px;
  position: absolute;
  right: 55px;
  top: 0;
  background-color: #fff;
  border-radius: 0 0 12px 12px;
  overflow: hidden;

  .Modal-close-x {
    display: none;
  }

  .BasketRestaurantName {
    font-size: 12px;
    color: #F58634;
    line-height: 18px;
    font-weight: 600;
    &:hover {
      cursor: pointer;
    }
  }

  ul {
    padding: 0px 35px 0px;
    max-height: 320px;
    overflow: auto;

    li {
      padding-top: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid #EDEDED;
      position: relative;

      .FoodPrice {
        display: flex;
        justify-content: space-between;
        font-size: 18px;
        color: $black;
        line-height: 23px;
        margin-bottom: 0;
        &:hover {
          cursor: pointer;
        }
      }

      &:last-of-type {
        border: none;
      }
    }

    border-bottom: 1px solid #EDEDED;
  }

  .DeliverySubtotal {
    padding: 16px 35px;
    border-bottom: 1px solid #EDEDED;

    .Subtotal,
    .Delivery {
      margin-bottom: 4px;
      display: flex;
      justify-content: space-between;
      font-size: 18px;
      color: #7C7C7C;
      line-height: 23px;

      span {
        font-size: 18px;
        color: $black;
        line-height: 23px;
      }
    }
  }


  .CheckOut {
    padding: 16px 35px;

    .YouPay {
      display: flex;
      justify-content: space-between;
      font-size: 18px;
      color: $black;
      font-weight: 600;
      line-height: 23px;
    }

    .CheckOutButton {
      width: 100%;
      background: $red;
      border-radius: 8px;
      text-align: center;
      font-size: 20px;
      color: #FFFFFF;
      font-weight: 700;
      line-height: 25px;
      padding-top: 11px;
      padding-bottom: 11px;
      -webkit-transition: 0.3s all;
      -moz-transition: 0.3s all;
      -ms-transition: 0.3s all;
      -o-transition: 0.3s all;
      transition: 0.3s all;
      border: 1px solid $red;

      &:hover {
        background-color: #fff;
        color: $red;
      }
    }

    .ClearCartButton {
      background: none;
      border: none;
      display: block;
      margin: 0 auto;
      font-size: 18px;
      color: #7C7C7C;
      font-weight: 600;
      line-height: 23px;
      margin-top: 15px;
      text-decoration: underline;

      &:hover {
        color: $black;
      }

    }

    .AddItemButton {
      width: 100%;
      height: 49px;
      border-radius: 8px;
      text-align: center;
      font-size: 20px;
      font-weight: 700;
      line-height: 25px;
      padding-top: 11px;
      padding-bottom: 11px;
      -webkit-transition: 0.3s all;
      -moz-transition: 0.3s all;
      -ms-transition: 0.3s all;
      -o-transition: 0.3s all;
      transition: 0.3s all;
      margin-bottom: 15px;
      &:hover {
        color: #F58634;
        border-color: #F58634;
      }
    }

    &.Empty {
      font-weight: 700;
    }
  }

  .RemoveCount {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .Count {
      button {
        background: none;
        border: none;
        padding-left: 0;
        padding-right: 0;

        svg {
          width: 32px;
        }

        &.RemoveCountLeft {
          margin-right: 22px;
        }

        &.RemoveCountRight {
          margin-left: 22px;

          svg {
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            transform: rotate(180deg);
          }
        }

        &:focus {
          outline: none;
        }
      }
    }

    .Remove {
      button {
        font-size: 12px;
        color: #EA4242;
        font-weight: 600;
        background: none;
        line-height: 15px;
        border: none;
      }
    }
  }

  @media screen and (max-width: 767px) {
    max-width: calc(100vw - 16px);
    position: relative;
    top: 100px;
    margin: 8px auto;
    left: 0px;
    margin-bottom: 24px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    //left: 50%;
    ul {
      li {
        .FoodPrice {
          span {
            padding-left: 15px;
            flex-shrink: 0;
            flex-grow: 0;
          }
        }
      }
    }
  }
}

.BasketTitle {
  padding: 35px;
  margin-bottom: 0;
  font-size: 18px;
  color: $black;
  line-height: 23px;
  padding-bottom: 30px;
  border-bottom: 1px solid #EDEDED;
}

/*==================================
            item details
==================================*/

.CustomItemDetails {
  max-width: 900px;
  width: 100% !important;
  background: #FFFFFF;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.10);
  border-radius: 12px;
  overflow: hidden;
  //padding: 24px;
  .Options {
    max-height: 449px;
    overflow: auto;
    //padding-top: 26px;
  }

  &::after {
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    background-color: #DDDDDD;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
  }

  &.ant-modal {
    padding-bottom: 0;
  }

  .Modal-close {
    z-index: 14;
  }

  .ItemDetailsLeft {
    padding-right: 24px;

    img {
      width: 100%;
      height: 353px;
      object-fit: cover;
      display: block;
      margin-bottom: 0;

    }

    h4 {
      margin-top: 12px;
      font-size: 24px;
      color: #1F1F1F;
      font-weight: 600;
      line-height: 31px;
      margin-bottom: 6px;
    }

    .ItemDetailsCal {
      font-size: 14px;
      color: #F58634;
      line-height: 18px;
      font-weight: 600;
      margin-bottom: 8px;
    }

    .ItemDetailsText {
      font-size: 16px;
      color: #7C7C7C;
      line-height: 20px;
      margin-bottom: 6px;
    }

    .ItemDetailsPrice {
      font-size: 24px;
      color: #1F1F1F;
      line-height: 31px;
      font-weight: 600;
      margin-bottom: 0;
    }

    .Count {
      margin-top: 9px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .Quantity {
        margin-bottom: 0;
        margin-right: 18px;
        font-size: 16px;
        color: #1F1F1F;
        font-weight: 600;
        line-height: 20px;
      }

      button {
        background: none;
        border: none;
        padding-left: 0;
        padding-right: 0;

        svg {
          width: 32px;
        }

        &.RemoveCountLeft {
          margin-right: 22px;
        }

        &.RemoveCountRight {
          margin-left: 22px;

          svg {
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            transform: rotate(180deg);
          }
        }

        &:focus {
          outline: none;
        }
      }
    }
  }

  .ItemDetailsRight {
    padding-left: 30px;
    padding-right: 26px;

    h4 {
      font-size: 18px;
      color: #1F1F1F;
      font-weight: 700;
      line-height: 23px;
      margin-bottom: 0px;

      & + span {
        font-size: 12px;
        color: #7C7C7C;
        font-weight: 600;
        line-height: 15px;
        //margin-top: -5px;
        display: block;
      }
    }

    ul {
      li {
        margin-bottom: 12px;
      }
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #F58634;
      border-color: #F58634;
    }

    .ant-checkbox-checked:after {
      border-color: #F58634;
    }

    .ant-checkbox-input:focus + .ant-checkbox-inner, .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner {
      border-color: #F58634;
    }

    textarea.ant-input {
      max-width: calc(100% - 15px);
    }

    .itemDetailsCatering {
      .GroupTitle {
        margin-bottom: 10px !important;
      }
      .cateringProducts {
        padding-right: 4px;
        div {
          display: flex;
          margin: 4px 0;
          align-items: center;
          justify-content: space-between;

          div {
            img {
              width: 40px;
              height: 40px;
              object-fit: cover;
              margin-right: 15px;
            }
          }
        }
      }
    }
  }

  .ItemDetailsSize {
    margin-top: 10px;
    margin-bottom: 10px;

    .ant-radio-group {
      width: 100%;
    }
  }

  .SizeItem {
    margin-bottom: 12px;

    .PlusPrice {
      margin-left: 16px;
      color: #7C7C7C;
    }

    span {
      //font-size: 16px;
      //color: #1F1F1F;
      //line-height: 20px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .AddComment {
    h4 {
      font-size: 18px;
      color: #1F1F1F;
      line-height: 23px;
      font-weight: 700;
      margin-bottom: 10px;
    }

    input {
      border: 1px solid #F3F3F3;
      border-radius: 12px;
      height: auto;
      line-height: 14px;
      font-size: 14px;
      padding: 14px 8px;
      width: 100%;
      resize: none;

      &:focus {
        outline: none;
      }
    }
  }

  .GroupTitle {
    margin-bottom: 30px;
    font-size: 18px;
    color: #1F1F1F;
    font-weight: 600;
    line-height: 23px;
    border-bottom: 1px solid rgba(217, 219, 224, 0.5);

    &::first-letter {
      text-transform: uppercase;
    }
  }

  .ItemDetailsRemove {
    margin-top: 20px;


    .PlusPrice {
      margin-left: 16px;
      color: #7C7C7C;
    }

    h4 {
      margin-bottom: 10px;
      font-size: 18px;
      color: #1F1F1F;
      font-weight: 700;
      line-height: 23px;

    }

    .Remove {
      text-transform: capitalize;
      line-height: 20px;
    }

    .Remove:not(:last-of-type) {
      margin-bottom: 12px;
    }
  }

  .AddBug {
    -webkit-transform: translateX(26px);
    -moz-transform: translateX(26px);
    -ms-transform: translateX(26px);
    -o-transform: translateX(26px);
    transform: translateX(26px);
    position: absolute;
    bottom: 0;
    right: 27px;
    max-width: 173px;
    width: 100%;

    button {
      //margin-top: 40px;
      border-radius: 8px;
      font-size: 14px;
      background: $red;
      color: #FFFFFF;
      border: 1px solid $red;
      font-weight: 600;
      text-align: center;
      display: block;
      margin-left: auto;
      padding: 8px;
      width: 100%;
      -webkit-transition: 0.3s all;
      -moz-transition: 0.3s all;
      -ms-transition: 0.3s all;
      -o-transition: 0.3s all;
      transition: 0.3s all;

      &:hover {
        background-color: #fff;
        color: $red;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .ItemDetailsLeft {
      padding-right: 0;
      position: static;

      .Count {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 5;
        display: block;

        .Quantity {
          margin-bottom: 10px;
        }
      }

      //padding-top: 32px;
    }
    .ant-modal-close-x {
      background-color: #fff;
      -webkit-border-radius: 12px;
      -moz-border-radius: 12px;
      border-radius: 12px;
    }
    .Options {
      padding-bottom: 50px;
    }
    .RemoveCountLeft {
      margin-right: 17px !important;
    }
    .RemoveCountRight {
      margin-left: 17px !important;
    }
    .ItemDetailsRight {
      padding-left: 0;
      padding-right: 0;
      padding-top: 30px;

      .AddBug {
        max-width: none;
        //padding-right: 24px;
        width: calc(100% - 15px);
        background-color: #fff;
        left: -27px;
        padding-top: 30px;
        right: auto;
        position: relative;

        button {
          max-width: none;
          width: auto;
        }

        &::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 1px;
          background-color: rgba(0, 0, 0, 0.07);
          left: 0;
          top: 0;
        }
      }

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: rgba(0, 0, 0, 0.07);
        left: 0;
        top: 30px;
      }
    }
    &::after {
      content: none;
    }
  }
}

/*---------*/

.ItemDetailsRightAddress {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
  left: 0;
  top: 0;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  padding-left: 24px;

  > div:not(.GetFood) {
    margin-top: 25px;

    input {
      background-color: #F3F3F3;
      padding-right: 15px;
    }
  }

  h4 {
    font-size: 40px;
    color: #1F1F1F;
    line-height: 50px;
    font-weight: 700;
    margin-bottom: 0;
  }

  .GetFood {
    position: absolute;
    bottom: 0;
    right: 0;
    max-width: 155px;
    width: 100%;

    button {
      position: static;
      bottom: 24px;
      display: inline-block;
      //font-size: 14px;
      //color: #FFFFFF;
      //font-weight: 700;
      background: $red;
      border-radius: 8px;
      width: 100%;
      padding: 8px;
      border: none;
      //background-image: url('../../img/ic_next_btn.svg');
      //background-repeat: no-repeat;
      //-webkit-background-size: 7px;
      //background-size: 7px;
      //background-position: calc(100% - 14px) 50%;
      &:hover {
        background-color: #d8232a;
        cursor: pointer;
      }

      img {
        height: 30px;
        width: 30px;
        margin: 0;
        @media screen and (max-width: 700px){
          height: 26px;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    align-items: flex-start;
    padding-left: 0;
    padding-right: 0;
    display: block;
    h4 {
      font-size: 22px;
      line-height: 27px;
    }
    .GetFood {
      max-width: none;
      display: flex;
      justify-content: flex-end;
      width: 100%;
      position: relative;
      margin-top: 40px;

      button {
        max-width: 65px;
      }
    }
  }
}

/* ================================
            animation
==================================*/
@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  10% {
    transform: translateY(-10px);
  }
  20% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(-5px);
  }
  40% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-2px);
  }
  60% {
    transform: translateY(0);
  }
  70% {
    transform: translateY(0);
  }
  80% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }

}

@keyframes bounceX {
  0% {
    transform: translateX(0);
  }
  10% {
    transform: translateX(10px);
  }
  20% {
    transform: translateX(0);
  }
  30% {
    transform: translateX(5px);
  }
  40% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(2px);
  }
  60% {
    transform: translateX(0);
  }
  70% {
    transform: translateX(0);
  }
  80% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(0);
  }

}

.SignIn {
  @media screen and (min-width: 767px) {
    max-width: 480px !important;
  }

  .SignInTitle {
    font-size: 30px;
    color: $black;
    line-height: 41px;
    font-weight: 700;
    margin-bottom: 55px;
  }

  .ant-modal-body {
    padding-bottom: 28px;
    padding-left: 40px;
    padding-right: 40px;
  }

  .login-form {
    label {
      font-size: 14px !important;
      color: #2E2E2E !important;
      font-weight: 600;
      line-height: 18px;
      margin-bottom: 13px;

      &::after, &::before {
        content: none;
      }
    }

    .ant-form-item-label {
      line-height: 18px;
    }
    .ant-col.ant-form-item-control {
      margin-bottom: 18px;
    }
    input {
      border: 1px solid #DDDDDD;
      border-radius: 12px;
      /**************/
      height: 56px;
    }

    .LoginForgot {
      text-align: center;

      a {
        margin-bottom: 55px;
        font-size: 14px;
        display: inline-block;
        color: #959595;
        line-height: 18px;
        text-decoration: underline;

        &:hover {
          color: $black;
        }
      }
    }

    .login-form-button {
      max-width: 258px;
      width: 100%;
      background: $red;
      border: 1px solid $red;
      border-radius: 8px;
      font-size: 16px;
      line-height: 20px;
      color: #FFFFFF;
      font-weight: 600;
      display: block;
      margin: 0 auto;
      padding-top: 14px;
      padding-bottom: 14px;
      height: auto;
      -webkit-transition: 0.3s all;
      -moz-transition: 0.3s all;
      -ms-transition: 0.3s all;
      -o-transition: 0.3s all;
      transition: 0.3s all;

      &:hover {
        background-color: #fff;
        color: $red;
      }
    }

    .DontHaveAccount {
      font-size: 14px;
      color: #7C7C7C;
      letter-spacing: 0;
      text-align: center;
      line-height: 18px;
      margin-top: 16px;
      margin-bottom: 0;

      a {
        font-weight: 700;
        color: $orange;
      }
    }
  }

}

.LocationAdded {
  padding-top: 76px;
  @media screen and (max-width: 1200px) {
    padding-top: 60px;
  }
}

.WelcomePage {
  padding: 80px 0 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  overflow: auto;
  position: absolute;
  left: 0;
  top: 0;
  //background-image: url('../../img/Cover-main-page.png');
  background-repeat: no-repeat;
  background-color: #fff;
  -webkit-background-size: 56%;
  background-size: 56%;
  z-index: 46;

  > img.WelcomeMoto {
    width: 274px;
    height: auto;
    position: absolute;
    left: 53%;
    bottom: 0;
    z-index: -1;
  }

  > img.WelcomeBg {
    width: 56%;
    height: 100%;
    object-fit: cover;
    object-position: right;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
  }

  .WelcomePageNav {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 3;

    ul {
      display: flex;
      padding-top: 36px;
      justify-content: flex-end;
      padding-right: 70px;

      li {
        cursor: pointer;
        font-size: 15px;
        color: #1F1F1F;
        font-weight: 700;
        line-height: 19px;
        margin-right: 30px;

        &:last-of-type {
          margin-right: 0;
          color: $red;

          a {
            color: $red;
          }
        }
      }
    }
  }

  .WelcomeReview {
    position: absolute;
    bottom: 46px;
    left: 8.3%;
    max-width: 474px;
    width: 100%;
    max-height: 227px;
    height: 100%;
    opacity: 0.95;
    background: #FFFFFF;
    border-radius: 24px;
    z-index: 5;
    padding: 30px 35px;

    h3 {
      margin-bottom: 0;
      font-size: 24px;
      color: #1F1F1F;
      font-weight: 700;
      line-height: 31px;
    }

    .WelcomeReviewRate {
      margin-bottom: 20px;

      > span {
        font-size: 14px;
        color: #7C7C7C;
        line-height: 18px;
        display: inline-block;
      }

      .ant-rate {
        -webkit-transform: translateY(-2px);
        -moz-transform: translateY(-2px);
        -ms-transform: translateY(-2px);
        -o-transform: translateY(-2px);
        transform: translateY(-2px);
        font-size: 14px;
      }

      .ant-rate-star {
        //font-size: 14px;
        i {
          color: #B3B3C3;
        }
      }

      .ant-rate-star-full {
        i {
          color: #F58634;
        }
      }
    }

    p {
      &.WelcomeReviewText {
        font-size: 18px;
        color: #1F1F1F;
        line-height: 23px;
      }
    }

    .slick-dots {
      bottom: -40px;
      text-align: left;

      li {
        margin-left: 0;

        button {
          &::before {
            font-size: 10px;
            color: #D6D6D6;
          }
        }

        &.slick-active {
          button {
            &::before {
              color: $red;
            }
          }
        }
      }
    }
  }

  .WelcomePageTerms {
    position: absolute;
    bottom: 0;
    right: 210px;
    padding-bottom: 28px;
    z-index: 3;

    ul {
      display: flex;

      li {
        cursor: pointer;
        font-size: 14px;
        color: $red;
        margin-right: 30px;
        text-decoration: underline;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .WelcomeReview {
      left: 15px;
    }
  }
  @media screen and (max-width: 1200px) {
    flex-direction: column;
    padding-left: 15px;
    padding-right: 15px;
    .Welcome {
      width: 100%;

      p.WriteLater {
        color: $black;
        margin-top: 10px;
      }
    }
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: rgba(255, 255, 255, 0.85);
    }
    > img.WelcomeBg {
      z-index: -1;
      width: 100%;
      object-position: left;
    }
    img.WelcomeMoto {
      display: none;
    }
    .WelcomeReview {
      left: 0;
      bottom: 0;
      position: relative;
      max-height: none;

      .slick-dots {
        bottom: -20px;
      }
    }
    .WelcomePageNav {
      ul {
        padding-top: 20px;
        padding-right: 30px;
      }
    }

    .WelcomePageTerms {
      text-align: center;
      left: 0;
      width: 100%;

      ul {
        justify-content: center;
      }
    }
  }
}

.Welcome {
  max-width: none;
  width: 50%;
  text-align: center;
  position: relative;
  z-index: 55;
  left: 0;
  top: 0;
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;

  > div {
    //text-align: center;
    max-width: 520px;
    margin-left: auto;
    margin-right: auto;

    input {
      //margin: 0 auto;
      background: #F3F3F3;
      border: 1px solid #EFEFEF;
      border-radius: 16px;
      max-width: 520px;
    }
  }

  img {
    width: 220px;
    margin-bottom: 70px;
  }

  h2 {
    font-size: 50px;
    color: #1F1F1F;
    line-height: 63px;
    font-weight: 700;
    //margin-bottom: 16px;
  }

  p {
    font-size: 20px;
    color: #747474;
    line-height: 25px;

    &.WriteLater {
      display: inline-block;
      font-size: 14px;
      color: #747474;
      margin-top: 40px;
      cursor: pointer;

      img {
        width: 20px;
        height: 12px;
        margin-bottom: 0;
      }

      &:hover {
        img {
          -webkit-animation: bounceX 1s infinite;
          -o-animation: bounceX 1s infinite;
          animation: bounceX 1s infinite;
        }
      }
    }
  }
}

.LandingLeftMenu::-webkit-scrollbar {
  width: 4px;
}

.LandingLeftMenu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

.LandingLeftMenu::-webkit-scrollbar-thumb {
  background-color: #a9a9a9;
  outline: 1px solid #708090;
}

/* left menu */
.LandingLeftMenu {
  position: sticky;
  top: 70px;
  min-width: 250px;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 20px;
  @media screen and (min-width: 767px) {
    max-height: calc(100vh - 80px);
    overflow: auto;
  }

  .restaurantType {
    button {
      display:block;
      margin: 0 auto 5px;

    }
  }

  .AddressAdded {
    position: relative;
    margin-top: 24px;
    padding-right: 17px;
    cursor: pointer;

    p {
      font-size: 14px;
      max-width: 170px;
      color: #1F1F1F;
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    svg {
      width: 23px;
      height: auto;
      -webkit-transform: translateY(-1px);
      -moz-transform: translateY(-1px);
      -ms-transform: translateY(-1px);
      -o-transform: translateY(-1px);
      transform: translateY(-1px);

      g {
        path {
          fill: #BCC3CC;
        }
      }
    }
  }

  .feature {
    p {
      font-size: 14px;
      color: #1B1B1B;
      line-height: 18px;
      margin-bottom: 6px;
    }


    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #F58634;
      border-color: #F58634;
    }

    .ant-checkbox-checked:after {
      border-color: #F58634;
    }

    .ant-checkbox-input:focus + .ant-checkbox-inner, .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner {
      border-color: #F58634;
    }


    .ant-radio-checked .ant-radio-inner {
      background-color: #F58634;
      border-color: #F58634;
    }

    .ant-radio-checked:after {
      border-color: #F58634;
    }

    .ant-radio-input:focus + .ant-radio-inner, .ant-radio-wrapper:hover .ant-radio-inner, .ant-radio:hover .ant-radio-inner {
      border-color: #F58634;
    }
  }

  h5 {
    font-size: 18px;
    color: #1B1B1B;
    font-weight: 600;
    line-height: 23px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .MenuS {
    margin-top: 20px;

    /*ul {
      padding: 14px 10px 14px 10px;
      background: #F3F3F3;
      border-radius: 8px;
      li {
        padding: 2px 7px;
        cursor: pointer;
        display: flex;
        width: 100%;
        margin-bottom: 4px;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        color: #1B1B1B;
        border: none;
        background-color: transparent;
        line-height: 18px;
        -webkit-transition: 0.3s all;
        -moz-transition: 0.3s all;
        -ms-transition: 0.3s all;
        -o-transition: 0.3s all;
        transition: 0.3s all;
        position: relative;

        &.SelectedCategory{
          //background-color: #e9e9e9;
          //border-radius: 10px;
          &::after{
            content: '';
            //content: url("../../img/check-mark.png");
            width: 14px;
            height: 14px;
            position: absolute;
            left: -8px;
            top: 50%;
            background-image: url("../../img/check-mark.png");
            -webkit-background-size: 10px;
            background-size: 8px;
            background-repeat: no-repeat;
            background-position: center center;
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);
          }
        }

        &:hover{
          //background-color: #e2e2e2;
          //border-radius: 8px;
          //border: 1px solid #dadada;
          color: $red;

          -webkit-transition: 0.15s all;
          -moz-transition: 0.15s all;
          -ms-transition: 0.15s all;
          -o-transition: 0.15s all;
          transition: 0.15s all;
        }
      }
    }*/

    .catScroll {
      max-height: 330px;
      overflow: auto;
      padding-left: 15px;
    }

    .catScroll::-webkit-scrollbar {
      width: 4px;
    }

    .catScroll::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    }

    .catScroll::-webkit-scrollbar-thumb {
      background-color: #a9a9a9;
      outline: 1px solid #708090;
    }


    .CategoryUl {
      padding: 14px 18px 14px 0px;
      background: #F3F3F3;
      border-radius: 8px;


      .ant-checkbox-group {
        width: 100%;
      }

      .ant-checkbox {
        display: none;

        & + span {
          display: flex;
          width: 100%;
          justify-content: space-between;
          margin-bottom: 4px;
          align-items: center;
          font-size: 14px;
          color: #1B1B1B;
          border: none;
          background-color: transparent;
          line-height: 18px;

          &:hover {
            color: $red !important;

            -webkit-transition: 0.15s all;
            -moz-transition: 0.15s all;
            -ms-transition: 0.15s all;
            -o-transition: 0.15s all;
            transition: 0.15s all;
          }
        }
      }

      .ant-checkbox-wrapper {
        display: block;
        margin-left: 0;

        &.ant-checkbox-wrapper-checked {
          position: relative;

          &::after {
            content: '';
            //content: url("../../img/check-mark.png");
            width: 14px;
            height: 14px;
            position: absolute;
            left: -8px;
            top: 50%;
            background-image: url("../../img/check-mark.png");
            -webkit-background-size: 10px;
            background-size: 8px;
            background-repeat: no-repeat;
            background-position: center center;
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);
          }
        }
      }
    }

  }

  .Price {
    margin-top: 20px;

    > div {
      display: flex;
      align-items: center;

      .ant-slider {
        width: 97%;
      }
    }

    span {
      font-size: 18px;
      color: $black;
      line-height: 23px;
    }

    .ant-radio-group.ant-radio-group-solid {
      background: #F3F3F3;
      border-radius: 6px;
      border: 2px solid #F3F3F3;
      padding: 2px;

    }

    .ant-radio-button-wrapper:not(:first-child)::before {
      content: none;
    }

    .ant-radio-button-wrapper {
      background-color: transparent;
      border: none;
      -webkit-border-radius: 6px;
      -moz-border-radius: 6px;
      border-radius: 6px;
      padding: 0 14px;

      &:focus {
        outline: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }
    }

    .ant-radio-button-wrapper-checked {
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      background-color: #fff;
    }

    .ant-radio-button-wrapper:hover {
      color: #ED3237;
    }

    .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      background-color: #fff;
      color: #ED3237;
      border-color: #fff;

    }

    .ant-slider:hover .ant-slider-track {
      background-color: #F58634;
    }

    .ant-slider-track {
      background-color: #F58634;
    }

    .ant-slider-handle {
      border: none;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
    }
  }

  .Delivery {
    margin-top: 20px;

    h5 {
      margin-bottom: 0;
    }

    > div {
      display: flex;
      align-items: center;

      .ant-slider {
        width: 100%;
      }
    }

    span {
      margin-left: 10px;
    }

    .ant-slider:hover .ant-slider-track {
      background-color: #ED3237;
    }

    .ant-slider-track {
      background-color: #ED3237;
    }

    .ant-slider-handle {
      border: none;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
    }
  }

  .Calories {
    margin-top: 20px;

    h5 {
      margin-bottom: 0;
    }

    > div {
      display: flex;
      align-items: center;

      .ant-slider {
        width: 100%;
      }
    }

    span {
      margin-left: 10px;
    }

    .ant-slider:hover .ant-slider-track {
      background-color: #F58634;
    }

    .ant-slider-track {
      background-color: #F58634;
    }

    .ant-slider-handle {
      border: none;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
    }
  }

  .SearchButton {
    margin-top: 40px;
    border-radius: 8px;
    font-size: 14px;
    background: $red;
    color: #FFFFFF;
    border: 1px solid $red;
    font-weight: 600;
    text-align: center;
    display: block;
    margin-left: auto;
    padding: 8px;
    width: 100%;
    -webkit-transition: 0.3s all;
    -moz-transition: 0.3s all;
    -ms-transition: 0.3s all;
    -o-transition: 0.3s all;
    transition: 0.3s all;

    &:hover {
      background-color: #fff;
      color: $red;
    }
  }

  @media screen and (max-width: 767px) {
    display: none;
    &.open {
      display: block;
      height: 100%;
      overflow: auto;
      padding-bottom: 80px;
      padding-top: 35px;
      //display: none;
      position: fixed;
      top: 40px;
      left: 0;
      width: 100%;
      background-color: #fff;
      z-index: 4;
    }
  }
}


/*===================================
  map
===================================*/

.ModalMap {
  position: relative;
  height: 506px;
  background-color: rgba(0, 0, 0, 0.15);
  width: calc(100% - 24px);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  overflow: hidden;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
}

.MapModalLocation {
  font-size: 16px;
  color: #7C7C7C;
  margin-bottom: 0;
  margin-top: 24px;
  line-height: 20px;
}

// select image
.item-img {
  width: 30px !important;
  margin-right: 5px;
  vertical-align: center;
}

/*===================================
        global input
===================================*/

.GlobalInput {
  p {
    font-size: 12px;
    color: #2E2E2E;
    font-weight: 700;
    line-height: 15px;
    margin-bottom: 6px;
  }

  .req:after {
    content: "*" !important;
    color: #b62b2b;
    display: inline !important;
    margin-left: 3px;
  }

  input, textarea {
    margin-bottom: 16px;
    width: 100%;
    border: 1px solid #DDDDDD;
    border-radius: 6px;
    font-size: 12px;
    color: #2E2E2E;
    line-height: 15px;
    padding: 12px 15px;

    &::placeholder {
      color: #959595;
    }

    &:focus {
      outline: none;
    }
  }

  textarea {
    min-height: 70px;
    resize: none;
    margin-bottom: 27px;
  }
}


.GuestInfoForm {
  .ant-form-item {
    margin-bottom: 16px;
  }

  label {
    font-size: 12px;
    color: #2E2E2E;
    font-weight: 700;
    line-height: 15px;
    margin-bottom: 6px;

    &::after, &::before {
      content: none;
    }
  }

  .ant-form-item-label {
    line-height: 18px;
  }

  input {
    height: auto;
    margin-bottom: 0;
    width: 100%;
    border: 1px solid #DDDDDD;
    border-radius: 6px;
    font-size: 12px;
    color: #2E2E2E;
    line-height: 15px;
    padding: 12px 15px;
  }
}

.ResetForm {
  max-width: 600px;
  margin: 120px auto 30px;

  .ResetTitle {
    font-size: 30px;
    color: $black;
    line-height: 41px;
    font-weight: 700;
    margin-bottom: 30px;
  }

  .ChangePasswordForm {

    .ant-form-item-label {
      line-height: 20px;
    }

    button {
      margin-top: 25px;
    }

    label {
      font-size: 14px !important;
      color: #2E2E2E !important;
      font-weight: 600;
      line-height: 18px;
      margin-bottom: 13px;

      &::after, &::before {
        content: none;
      }
    }

    .ant-form-item-label {
      line-height: 18px;
    }

    input {
      border: 1px solid #DDDDDD;
      border-radius: 12px;
      /**************/
      height: 45px;
    }

    .change-password-form-button {
      max-width: 200px;
      width: 100%;
      background: $red;
      border: 1px solid $red;
      border-radius: 8px;
      font-size: 16px;
      line-height: 11px;
      color: #FFFFFF;
      font-weight: 600;
      display: block;
      padding-top: 14px;
      padding-bottom: 14px;
      height: auto;
      -webkit-transition: 0.3s all;
      -moz-transition: 0.3s all;
      -ms-transition: 0.3s all;
      -o-transition: 0.3s all;
      transition: 0.3s all;

      &:hover {
        background-color: #fff;
        color: $red;
      }
    }
  }
}

.dis-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-gap: 35px;
}

.termsModal {
  max-width: 1000px;
  width: 100% !important;

  p {
    margin-bottom: 0;

    &.name {
      font-size: 16px;
      font-weight: bold;
    }
  }
}


/* square item*/

.SquareItem {
  img {
    -webkit-transition: 0.3s all;
    -moz-transition: 0.3s all;
    -ms-transition: 0.3s all;
    -o-transition: 0.3s all;
    transition: 0.3s all;
  }

  &:hover {
    img {
      opacity: 0.7;
    }
  }
}

.ant-dropdown-menu {
  .ant-dropdown-menu-item {
    a {
      text-align: center;
      font-size: 15px;
      font-weight: 600;
    }
  }
  & :last-child {
   a {
     color: #ED3237;
   }
  }
}
.pageNotFound {
  padding-top: 160px;
  text-align: center;
  justify-content: center;
  min-height: 100%;
  @media screen and (min-width: 767px) {
    height: calc(100vh - 377px);
  }
  p {
    a {
      color: #F58634
    }
  }
  @media screen and (max-width: 767px) {
    h1 {
      font-size: 25px;
    }
  }
}

#registration_form{
  button {
    margin-top: 25px;
  }
}
.pointer{
  cursor: pointer;
}

$black: #1F1F1F;
$red: #ED3237;
$orange: #F58634;

//.PanelRow {
//  display: flex;
//  justify-content: center;
//  @media screen and (max-width: 1400px) {
//    justify-content: flex-start;
//  }
//}

/*.SearchLeftMenu {
  min-width: 250px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  background-color: #FCFCFC;
  height: calc(100% + 160px);

  .AddressAdded {
    position: relative;
    margin-top: 24px;
    padding-right: 17px;
    cursor: pointer;
    p{
      font-size: 14px;
      max-width: 170px;
      color: #1F1F1F;
    }
    svg {
      width: 23px;
      height: auto;
      -webkit-transform: translateY(-1px);
      -moz-transform: translateY(-1px);
      -ms-transform: translateY(-1px);
      -o-transform: translateY(-1px);
      transform: translateY(-1px);
      g {
        path {
          fill: #BCC3CC;
        }
      }
    }
  }

  .feature {
    p {
      font-size: 14px;
      color: #1B1B1B;
      line-height: 18px;
      margin-bottom: 6px;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #F58634;
      border-color: #F58634;
    }

    .ant-checkbox-checked:after {
      border-color: #F58634;
    }

    .ant-checkbox-input:focus + .ant-checkbox-inner, .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner {
      border-color: #F58634;
    }
  }

  h5 {
    font-size: 18px;
    color: #1B1B1B;
    font-weight: 600;
    line-height: 23px;
  }

  .MenuS {
    margin-top: 20px;

    ul {
      padding: 14px 18px 14px 20px;
      background: #F3F3F3;
      border-radius: 8px;

      button {
        display: flex;
        width: 100%;
        margin-bottom: 4px;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        color: #1B1B1B;
        border: none;
        background-color: transparent;
        line-height: 18px;

        &:focus {
          outline: none;
        }
      }
    }
  }

  .Price {
    margin-top: 20px;

    .ant-radio-group.ant-radio-group-solid {
      //padding: 2px;
      background: #F3F3F3;
      border-radius: 6px;
      border: 2px solid #F3F3F3;
      padding: 2px;

    }

    .ant-radio-button-wrapper:not(:first-child)::before {
      content: none;
    }

    .ant-radio-button-wrapper {
      background-color: transparent;
      border: none;
      -webkit-border-radius: 6px;
      -moz-border-radius: 6px;
      border-radius: 6px;
      padding: 0 14px;

      &:focus {
        outline: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }
    }

    .ant-radio-button-wrapper-checked {
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      background-color: #fff;
    }

    .ant-radio-button-wrapper:hover {
      color: #ED3237;
    }

    .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      background-color: #fff;
      color: #ED3237;
      border-color: #fff;

    }
  }

  .Delivery {
    margin-top: 20px;

    h5 {
      margin-bottom: 0;
    }

    > div {
      display: flex;
      align-items: center;

      .ant-slider {
        width: 100%;
      }
    }

    span {
      margin-left: 10px;
    }

    .ant-slider:hover .ant-slider-track {
      background-color: #ED3237;
    }

    .ant-slider-track {
      background-color: #ED3237;
    }

    .ant-slider-handle {
      border: none;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
    }
  }

  .Calories {
    margin-top: 20px;

    h5 {
      margin-bottom: 0;
    }

    > div {
      display: flex;
      align-items: center;

      .ant-slider {
        width: 100%;
      }
    }

    span {
      margin-left: 10px;
    }

    .ant-slider:hover .ant-slider-track {
      background-color: #F58634;
    }

    .ant-slider-track {
      background-color: #F58634;
    }

    .ant-slider-handle {
      border: none;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
    }
  }
}*/


/*========================================
          Landing Panel
========================================*/

.Search {
  padding-top: 100px;
  display: flex;
  width: 100%;
  padding-bottom: 160px;

  .LandingPanel {
    padding-right: 40px;
    width: calc(100% - 250px);

    > div {
      padding-left: 40px;

      &.Restaurant {
        display: block;
        border-bottom: 1px solid #DDDDDD;
        padding-bottom: 24px;
      }
    }

    h4 {
      font-size: 24px;
      color: $black;
      font-weight: 700;
      line-height: 31px;
      margin-bottom: 0;
      //padding-left: 40px;
      //
      span {
        color: $red;
      }

      & + img {
        display: block;
        max-width: 398px;
        width: 100%;
        margin: 40px auto;
      }
    }

    .RestaurantCount {
      font-size: 18px;
      color: #7C7C7C;
      line-height: 23px;
    }

    .PanelTitle {
      position: relative;
      margin-bottom: 52px;

      &::after {
        content: '';
        position: absolute;
        width: 94px;
        height: 4px;
        background: #E85D42;
        left: 0;
        bottom: -20px;
      }
    }


    .RestaurantPanel {
      margin-top: 50px;
      padding-bottom: 30px;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: calc(100% - 40px);
        height: 1px;
        background-color: #DDDDDD;
      }

      h5 {
        font-size: 24px;
        color: #1B1B1B;
        line-height: 31px;
        font-weight: 700;
        margin-bottom: 0;

        & + p {
          font-size: 18px;
          color: #7C7C7C;
          margin-bottom: 0;
          line-height: 23px;
        }
      }


      .RectangleItem {
        cursor: pointer;
        //margin-right: 38px;
        margin-bottom: 30px;

        &:last-of-type {
          margin-right: 0;
        }

        img {
          display: block;
          width: 100%;
          border-radius: 12px;
          max-height: 150px;
          object-fit: cover;
        }

        p {
          margin-top: 8px;
          margin-bottom: 0;
          font-size: 16px;
          color: #1F1F1F;
          line-height: 20px;
          font-weight: 600;
        }

        span {
          display: block;
          font-size: 14px;
          color: #7C7C7C;
          line-height: 18px;

          &.PanelMin {
            margin-top: 4px;
            font-size: 14px;
            color: #1f1f1f;
            line-height: 18px;
            font-weight: 600;

            span {
              display: inline;
              font-weight: 400;
              color: #7C7C7C;
            }
          }

          &.PanelDelivery {
            margin-top: 4px;
          }

          &.PanelFreeDelivery {
            margin-top: 8px;
            display: inline-block;
            background: #F58634;
            border-radius: 6px;
            padding: 4px 6px;
            font-size: 12px;
            color: #FFFFFF;
            font-weight: 700;
            text-transform: uppercase;
          }
        }
      }
    }

    @media screen and (max-width: 1440px) {
      width: 100%;
    }
  }
  @media screen and (max-width:767px) {
    .LandingPanel {
      padding-right: 0;
      .RestaurantPanel{
        padding-right: 15px;
        padding-left: 15px;
        &::after{
          width: calc(100% - 30px);
          left: 15px;
        }

        >div{
          justify-content: center;
          .RectangleItem{
            cursor: pointer;
            width: 100%;
            //text-align: center;
            flex-grow: 0;
            flex-shrink: 0;
            img{
              width: calc(100% - 15px);
              height: 200px;
              max-height: none;
            }
          }
        }
      }
      > div
      {
        &.Restaurant{
          padding-right: 15px;
          padding-left: 15px;
        }
      }
    }
  }
}


.slick-arrow.slick-next:not(.slick-disabled) {
  left: auto;
  right: 25px;
  height: 100%;
  z-index: 3;


  &::before {
    position: absolute;
    content: '';
    width: 18px;
    height: 18px;
    left: calc(100% + 5px);
    top: 84px;
    -webkit-transform: translate(-100%, -100%);
    -moz-transform: translate(-100%, -100%);
    -ms-transform: translate(-100%, -100%);
    -o-transform: translate(-100%, -100%);
    transform: translate(-100%, -100%);
    z-index: 3;
    opacity: 1 !important;
    background-image: url('../../img/next.png');
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center center;

  }
}

.slick-arrow.slick-prev:not(.slick-disabled) {
  z-index: 3;
  left: -25px;
  height: 100%;


  &::before {
    position: absolute;
    content: '';
    width: 18px;
    height: 18px;
    left: 10px;
    top: 84px;
    -webkit-transform: translate(-100%, -100%);
    -moz-transform: translate(-100%, -100%);
    -ms-transform: translate(-100%, -100%);
    -o-transform: translate(-100%, -100%);
    transform: translate(-100%, -100%);
    z-index: 3;
    opacity: 1 !important;
    background-image: url('../../img/back.png');
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center center;

  }
}



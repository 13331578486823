.Abb{
  header{
    background-position: left center;
  }
  padding-bottom: 170px;
@media screen and (max-width:767px) {
  padding-bottom: 70px;
}
}

.WhoWe {
  padding-top: 58px;

  h1 {
    font-size: 56px;
    color: #1F1F1F;
    font-weight: 700;
    line-height: 70px;
    position: relative;
    margin-bottom: 16px;
    display: inline-block;

    img {
      position: absolute;
      bottom: 35px;
      z-index: -1;
      left: -48px;
      height: 51px;
    }

    & + p {
      font-size: 16px;
      color: #1F1F1F;
      text-align: center;
      line-height: 29px;
    }
  }
  @media screen and (max-width: 575px) {
    h1{
      font-size: 40px;
      img{
        //display: none;
      }
      & + p {
        text-align: justify;
      }
    }
  }
}

.WeDeliver {
  h3 {
    padding-top: 140px;
    font-size: 56px;
    color: #1F1F1F;
    font-weight: 700;
    line-height: 70px;
    position: relative;
    margin-bottom: 16px;
    display: inline-block;

    img {
      position: absolute;
      bottom: 35px;
      z-index: -1;
      left: -48px;
      height: 51px;
    }

    & + p {
      font-size: 16px;
      color: #1F1F1F;
      line-height: 29px;
    }
  }
  @media screen and (max-width: 575px) {
    text-align: center;
    h3{
      font-size: 40px;
      text-align: center;
      img{
        //display: none;
      }
      & + p {
        text-align: justify;
      }
    }
  }
}

.AboutImg {
  margin-top: 76px;
  img {
    height: 700px;
    width: 100%;
    object-fit: cover;
  }
  @media screen and (max-width: 767px) {
    margin-top: 20px;
    img{
      height: 300px;
    }
  }
}

.AboutBg{
  position: relative;
  &::after{
    position: absolute;
    z-index: -1;
    content: '';
    width: 407px;
    height: 323px;
    background-image: url('../../img/ic_moto.png');
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center center;
    bottom: 0;
    left: 0;
  }
  @media screen and (max-width: 767px) {
    &::after{
      content: none;
    }
  }
}
$black: #1F1F1F;
$red: #ED3237;
$orange: #F58634;
.ant-picker {
  width: 100%;

  .ant-picker-suffix {
    margin-bottom: 5px;
  }

  .ant-picker-clear {
    .anticon-close-circle {
      margin-bottom: 7px;
    }
  }
}
.ant-picker-input > input {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -ms-flex: auto;
  flex: auto;
  min-width: 1px;
  height: auto;
  padding: 0;
  background: transparent;
  border: 0;
}
.customTipActivator {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  background: transparent;
  cursor: pointer;

  & + .customTipActive {
    button {
      background-color: #dddddd;
      border-color: #ddd;
    }
  }
}

.credit-card-section {
  position: relative;

  img {
    position: absolute;
    width: 24px;
    left: 10px;
    top: 13px;
  }

  input {
    padding-left: 40px !important;
  }
}

.OrderAddress .GlobalInput {
  .ant-select.ant-select-enabled {
    margin-bottom: 16px;
  }

  textarea {
    margin-bottom: 8px;
  }

  .ant-select.ant-select-enabled {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
  }

  .ant-select-selection:hover {
    border-color: #DDDDDD;
    border-right-width: 1px !important;
  }

  .ant-select-selection--single, .ant-select-selector {
    height: 41px;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;

    .ant-select-selection__rendered, .ant-select-selection-item {
      line-height: 41px;
    }
  }

  //.ant-select-selector {
  //  height: 41px;
  //  -webkit-box-shadow: none !important;
  //  -moz-box-shadow: none !important;
  //  box-shadow: none !important;
  //  .ant-select-selection__rendered{
  //    line-height: 41px;
  //  }
  //}
}


.customTipActive {
  display: flex;

  button {
    background-color: $orange;
    border: 1px solid $orange;
    -webkit-border-radius: 6px 0 0 6px;
    -moz-border-radius: 6px 0 0 6px;
    border-radius: 6px 0 0 6px;
    padding: 8px 4px;
    //font-size: 15px;
    color: #fff;
    font-weight: 700;
    width: max-content;
    white-space: nowrap;
    font-size: 12px;
    //color: #2E2E2E;
    cursor: default !important;
  }

  input {
    padding: 8px 4px;
    //font-size: 15px;
    font-size: 12px;
    //color: #2E2E2E;
    border: 1px solid #dddddd;
    border-left: 0;
    -webkit-border-radius: 0 6px 6px 0;
    -moz-border-radius: 0 6px 6px 0;
    border-radius: 0 6px 6px 0;
    width: 100%;

    &:focus {
      outline: none;
    }
  }

  .activateDonate {
    cursor: pointer;
  }
}

.tipDiv {
  text-align: center;
  margin-bottom: 10px;

  &:first-of-type {

  }

  label {
    cursor: pointer;
    display: block;
    margin-bottom: 0;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #DDDDDD;
    -webkit-border-radius: 0 6px 6px 0;
    -moz-border-radius: 0 6px 6px 0;
    border-radius: 0 6px 6px 0;
    -webkit-transition: 0.3s all;
    -moz-transition: 0.3s all;
    -ms-transition: 0.3s all;
    -o-transition: 0.3s all;
    transition: 0.3s all;
    font-weight: 700;


    &[for='tip-with-credit-card'] {
      border-right-width: 0;
      -webkit-border-radius: 6px 0 0 6px;
      -moz-border-radius: 6px 0 0 6px;
      border-radius: 6px 0 0 6px;
    }

    &:hover {
      background-color: #dddddd;
    }
  }

  input[type="radio"] {
    display: none;

    &:checked + label {
      background-color: $orange;
      color: #fff;
      border-color: $orange;
    }
  }

  @media screen and (max-width: 575px) {
    label {
      font-size: 14px;
    }
  }
}

.tipPercentage {
  display: flex;
  justify-content: space-between;

  > div {
    width: 100%;
    text-align: center;

    &:first-of-type {
      label {
        -webkit-border-radius: 6px 0 0 6px;
        -moz-border-radius: 6px 0 0 6px;
        border-radius: 6px 0 0 6px;
      }
    }

    &:last-of-type {
      label {
        -webkit-border-radius: 0 6px 6px 0;
        -moz-border-radius: 0 6px 6px 0;
        border-radius: 0 6px 6px 0;
      }
    }

    &:nth-of-type(n+2) {
      label {
        border-left: 0;
      }
    }
  }

  label {
    cursor: pointer;
    display: block;
    padding: 8px 4px;
    margin-bottom: 0;
    border: 1px solid #DDDDDD;
    -webkit-transition: 0.3s all;
    -moz-transition: 0.3s all;
    -ms-transition: 0.3s all;
    -o-transition: 0.3s all;
    transition: 0.3s all;
    font-size: 12px;
    color: #2E2E2E;
    font-weight: 700;

    &:hover {
      background-color: #dddddd;
    }
  }

  input[type="radio"] {
    display: none;

    &:checked + label {
      background-color: $orange;
      color: #fff;
      border-color: $orange;
    }
  }
}

.Order {
  padding-top: 130px;
  max-width: 995px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  margin-bottom: 160px;
  align-items: flex-start;

  h3 {
    font-size: 32px;
    color: #2E2E2E;
    line-height: 40px;
    font-weight: 700;
    margin-bottom: 33px;
  }

  .GuestInfo {
    font-size: 16px;
    color: #2E2E2E;
    line-height: 20px;
    font-weight: 700;
  }

  .OrderAddress {
    padding-left: 15px;
    padding-right: 15px;
  }

  form {
    //padding-left: 15px;
    //padding-right: 15px;
    max-width: 580px;
    width: 100%;
    margin: 0 auto;

    hr {
      height: 1px;
      //margin: 0;
      margin-top: 0;
      margin-bottom: 32px;
      width: 100%;
      border-color: #DDDDDD;
    }
  }

  .OrderDetails {
    //padding-left: 15px;
    //padding-right: 15px;
    max-width: 328px;
    width: 100%;

    > div {

      -webkit-border-radius: 7px;
      -moz-border-radius: 7px;
      border-radius: 7px;
      overflow: hidden;
      border: 1px solid #DDDDDD;
    }

    .OrderMap {
      height: 122px;
      background-color: #ececee;
      position: relative;
    }

    .OrderMenu {
      padding-left: 21px;
      padding-right: 21px;
      border-bottom: 1px solid #EDEDED;

      ul {
        li {
          p {
            width: 195px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          span {
            white-space: nowrap;
          }
        }
      }

      ul:first-of-type {
        li {
          padding: 20px 10px;
          font-size: 16px;
          color: $black;
          letter-spacing: 0;
          line-height: 20px;
          border-bottom: 1px solid #EDEDED;
          display: flex;
          justify-content: space-between;

          &:not(:first-child) {
            &:hover {
              cursor: pointer;
            }
          }

          span {
            white-space: nowrap;
          }

          p {
            color: #F58634;
            width: 195px;
          }
        }
      }

      ul:last-of-type {
        padding-top: 18px;
        padding-bottom: 13px;

        li {
          font-size: 16px;
          color: #7C7C7C;
          letter-spacing: 0;
          line-height: 20px;
          display: flex;
          margin-bottom: 7px;
          justify-content: space-between;

          span {
            color: $black;
            // TODO discuss with PSYCHO
            //width: 20%;
          }
        }
      }
    }

    .OrderPay {
      padding: 19px 30px 33px;

      > p {
        margin-bottom: 30px;
        font-size: 16px;
        color: #1F1F1F;
        display: flex;
        justify-content: space-between;
        font-weight: 700;
        line-height: 20px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      button {
        border-radius: 8px;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        padding: 14px 0;
        display: block;
        width: 100%;
        text-align: center;
        -webkit-transition: 0.3s all;
        -moz-transition: 0.3s all;
        -ms-transition: 0.3s all;
        -o-transition: 0.3s all;
        transition: 0.3s all;
        margin-top: 30px;
        height: auto !important;
      }

      .orderButton {
        background: #ED3237;
        color: #FFFFFF;
        border: 1px solid $red;

        &:hover {
          background-color: #fff;
          color: $red;
        }
      }

      .addItemButton {
        &:hover {
          color: #F58634;
          border-color: #F58634;
        }
      }
    }
  }

  .OrderComplete {
    max-width: 464px;

    img {
      //padding-top: 20px;
      width: 256px;
      display: block;
      margin-bottom: 33px;
    }

    h3 {
      font-size: 32px;
      color: #2E2E2E;
      font-weight: 700;
      line-height: 40px;
      margin-bottom: 33px;
    }

    p {
      font-size: 16px;
      color: #1F1F1F;
      line-height: 20px;
      margin-bottom: 40px;
    }

    button {
      background: $orange;
      border-radius: 8px;
      font-size: 16px;
      color: #FFFFFF;
      text-align: center;
      font-weight: 700;
      border: 1px solid $orange;
      padding: 14px 0;
      display: block;
      max-width: 287px;
      width: 100%;
    }
  }

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
    form {
      max-width: none;
    }
    .OrderAddress {
      width: 100%;
      max-width: none;
      margin-bottom: 40px;
    }
    .OrderDetails {
      max-width: none;
    }
  }
}

.CardSelect {
  margin-bottom: 16px !important;
  width: 100%;

  .ant-select-selector {
    height: 41px !important;
  }
}

.inCardSelect {
  display: flex;
  align-items: center;
  padding-top: 5px;

  img {
    height: 30px;
  }

  .card_name {
    display: flex;
    font-size: 12px;
    color: #2E2E2E;
    font-weight: 700;
    line-height: 15px;

    p {
      padding-left: 15px;
      margin: 0;
    }
  }
}

.add_New {
  font-weight: 700;
  padding-left: 15px;
}

@media screen and (min-width: 1200px) {
  .Order {
    max-width: 1000px;
  }
  .OrderDetails {
    max-width: 300px;
  }
  .OrderAddress {
    max-width: 700px;
  }
}

// WF EDIT

@media screen and (max-width: 600px) {
  #launcher {
    bottom: 67px !important;
  }
}

input.couponErr {
  border-color: red !important;
}

p.couponErr {
  color: rgb(149, 22, 22) !important;
}

p.couponErrNo {
  color: rgb(0, 117, 39) !important;
}

span.underlined {
  text-decoration: line-through;
}

span.displayNone {
  display: none;
}

$black: #1F1F1F;
$red: #ED3237;
$orange: #F58634;
$primaryColor1: #e7792c;
$primaryColor2: #d93d2c;
$modalShadow: 0 2px 3px rgba(0, 0, 0, 0.125);
$checkboxBorder: #bcc3cc;


.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid transparent !important;
}

.balance_content {
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 37px !important;
    width: 150px;
  }
}

.ant-breadcrumb a:hover {
  color: $red !important;
}

.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
  border-right: 0px !important;
}

.react-tabs__tab--disabled {
  background: #d6d6d6;
  border-radius: 5px 5px 0 0;
}

.new_order_layer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00000063;
  z-index: 98;
}

.navbar_new_order {
  position: fixed;
  top: 15px;
  left: 0;
  right: 0;
  margin: auto;
  width: 200px;
  background: #42b242;
  padding: 11px;
  text-align: center;
  z-index: 9;
  border-radius: 7px;
  color: #ffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.new_order_dialog {
  .layer_click {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  position: fixed;
  top: 10px;
  left: 0;
  right: 0;
  margin: auto;
  width: 90%;
  background: #42b242;
  min-height: 500px;
  height: 90%;
  z-index: 999;
  border-radius: 10px;
  text-align: center;

  .close_modal {
    position: absolute;
    top: 5px;
    right: 15px;
    color: #FFFFFF;
  }

  .icon-notif {
    font-size: 25vh;
    color: #fff;
  }

  .notification-block {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .info {
    z-index: 999999;
    position: relative;

    h3 {
      font-size: 3vh;
      color: #fff;
    }

    .dismiss-btn {
      background: #30a830;
      border-radius: 8px;
      max-width: 258px;
      display: flex;
      align-items: center;
      margin: 20px auto 0;
      font-size: 15px;
      color: #FFFFFF;
      letter-spacing: 0;
      font-weight: 500;
      border: 1px solid #0a5e0a;
      padding: 3px 40px;
      transition: .3s all;
    }
  }
}

.TableListImg {
  width: 75px;
  cursor: pointer;
}

.orderButtons {
  //margin-left: auto;
  text-align: right;
}

.ant-btn.cancelButton {
  margin-left: -113px;
  background-color: red;
  color: white;
  border-color: red;
}

.cancel {
  text-align: center;
  color: red;
}

.ant-btn.item {
  background-color: red;
  border-color: red;
  color: white;
}

.card_adjust {
  display: flex;
  justify-content: space-between;

  .name_count {
    .name {
      color: #e7792b;
      font-weight: bold;
      margin-left: 10px;
    }

    .info_item {
      display: flex;
      justify-content: space-between;

      .first_info {
        margin-right: 20px;
      }
    }
  }

  .remove_item {
    background: transparent;
    border: none;
    color: red;
    position: absolute;
    right: 0;
    top: -5px;
  }
}

#partnerDetailsMoreInfoForm_fein_restaurant {
  text-transform: uppercase;
}

.ant-rate-disabled {
  .ant-rate-star {
    div:hover {
      transform: scale(1.0);
    }
  }
}

.item_ord {
  &.canceled {
    background: #ffe5e5;
  }

  &.add_item {
    background: #ffff0033;
  }
}

.banner_img {
  width: 100%;

  .ant-upload {
    width: 100% !important;
  }
}

.edit_restaurant {
  background: transparent;
  border: none;
  margin-top: -15px !important;
  font-size: 23px;
  transition: .3s;

  &:hover {
    transform: scale(1.1);
    transition: .3s;
  }
}

.edit_profile {
  width: 100% !important;
  height: 98%;
  top: 0;

  .ant-modal-body {
    height: 87%;
  }
}

.layer_view_tab {
  position: absolute;
  width: 100%;
  top: 0;
  height: 100%;
  left: 0;
}

.banner_profile {
  background-size: cover;
  background-position: center;

  .logo_name {
    padding: 60px;
    display: flex;
    align-items: center;

    .img {
      width: 130px;
      border-radius: 15px;
      overflow: hidden;
    }

    .name {
      margin-left: 30px;

      h3 {
        color: white;
        font-size: 25px;
        font-weight: bold;
      }

      .it_info {
        color: white;
      }
    }
  }
}


.hover_banner_profile {
  transition: 0.7s;
}

.orderDone {
  background-color: #4aa209 !important;
  border-color: #4aa209 !important;
}

.orderDone:hover {
  background-color: #fff !important;
  color: #4aa209 !important;
  border-color: #4aa209 !important;
}

.btn_adjust {
  width: 100% !important;
  background: #fafafa !important;
  color: #000 !important;
  border-color: #dcdcdc !important;
  height: 40px !important;
  text-align: left !important;
}

.card_order_view {
  padding: 0;

  p {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 17px;
    border-bottom: 1px solid silver;
    color: #000;
  }
}

.head_item {
  display: flex;
  align-items: center;
  margin-top: 6px;
  justify-content: space-between;
  margin-bottom: 6px;
  padding-left: 5px;
}

.CustomItemDetails.ingrid .ItemDetailsRight {
  padding-top: 0 !important;

  &::after {
    background-color: transparent !important;
  }

  .Count {
    margin-bottom: 10px;

    .Quantity {
      margin-bottom: 5px;
    }
  }
}

.product_item_add {
  cursor: pointer;
  color: #000;
  margin-bottom: 10px;
  border: solid 1px #d9d9d9;
  padding: 3px 15px;
  min-height: 60px;

  &.active {
    background-color: #a2d589;
  }

  .body_it {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .size_and_comment {
    margin-top: 5px;
    border-top: solid 1px silver;

    ul {
      list-style: disc;
    }
  }
}

.CustomItemDetails.ingrid::after {
  background-color: transparent !important;
}

.CustomItemDetails.ingrid .ant-modal-body {
  padding: 5px;
}

.Count {
  button {
    background: transparent;
    border: none;

    &.RemoveCountRight {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}

.bodi_add_item {
  height: 300px;
  overflow: auto;
  margin-bottom: 10px;
}

.date_time {
  p {
    margin-bottom: 0;
  }
}

.card_custom {
  padding: 15px;
  border: solid 1px silver;
  margin-top: 10px;
  color: #000;

  p {
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    color: #000;

  }
}

.total_card {
  padding: 15px;
  text-align: right;
  border: solid 1px silver;
  margin-top: 5px;
  color: #000;

  .bold {
    font-weight: bold;

    &:last-child {
      font-size: 18px;
    }
  }
}

.promotion_item {
  border: solid 1px silver;
  padding: 10px;
  margin-top: 10px;
  border-radius: 4px;

  span {
    font-size: 35px;
    font-weight: bold;
  }

  h3 {
    font-size: 18px;
    font-weight: bold;
  }
}

.hover_banner_profile:hover {
  background-color: #000c17;
  opacity: 0.7;
  transition: 0.7s;
}

.order_item_partnor {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .item {
    display: flex;

    div {
      margin-right: 20px;
    }
  }
}

.terms_body {
  height: 100%;
  overflow: auto;
  margin-top: 10px;
  border: solid 1px silver;
  padding: 8px;
  border-radius: 5px;

  h3 {
    font-weight: bold;
  }

  .name {
    font-weight: bold;
    color: #000;
  }
}

.blink {
  -webkit-animation: glowing 1500ms infinite;
  -moz-animation: glowing 1500ms infinite;
  -o-animation: glowing 1500ms infinite;
  animation: glowing 1500ms infinite;
}

@-webkit-keyframes glowing {
  0% {
    -webkit-box-shadow: 0 0 3px #42b242;
  }
  50% {
    -webkit-box-shadow: 0 0 30px #42b242;
  }
  100% {
    -webkit-box-shadow: 0 0 3px #42b242;
  }
}

@-moz-keyframes glowing {
  0% {
    -moz-box-shadow: 0 0 3px #42b242;
  }
  50% {
    -moz-box-shadow: 0 0 30px #42b242;
  }
  100% {
    -moz-box-shadow: 0 0 3px #42b242;
  }
}

@-o-keyframes glowing {
  0% {
    box-shadow: 0 0 3px #42b242;
  }
  50% {
    box-shadow: 0 0 30px #42b242;
  }
  100% {
    box-shadow: 0 0 3px #42b242;
  }
}

@keyframes glowing {
  0% {
    box-shadow: 0 0 3px #42b242;
  }
  50% {
    box-shadow: 0 0 30px #42b242;
  }
  100% {
    box-shadow: 0 0 3px #42b242;
  }
}

.group_btn_order {
  justify-content: center;
  width: 100%;
  margin-top: 10px;
}

.empty_result {
  text-align: center;
  padding: 30px;
}

.OrderInfoModal {
  max-width: 1100px;

  .ant-radio-wrapper {
    display: block;
  }

  .orderAccept {
    background-color: #4aa209;
    border-color: #4aa209;

    &:focus {
      background-color: #4aa209;
      border-color: #4aa209;
    }

    &:hover {
      background-color: #fff;
      color: #4aa209;
      border-color: #4aa209;
    }
  }

  .orderDone {
    background-color: #4aa209;
    border-color: #4aa209;

    &:focus {
      background-color: #4aa209;
      border-color: #4aa209;
    }

    &:hover {
      background-color: #fff;
      color: #4aa209;
      border-color: #4aa209;
    }
  }
}


.Content {
  box-shadow: 0 7px 14px 0 rgba(59, 64, 94, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);

  .ContentHeader {
    display: flex;
    background-color: #f4f4f4;
    padding: 15px 17px 10px 15px;
    border-bottom: 1px solid #e3e7ee;
    line-height: 24px;
    margin: 0;
    justify-content: space-between;
    align-items: center;

    .partnerActionButtons {
      display: flex;

      > img {
        margin-right: 15px;
        cursor: pointer;
      }
    }

    h1 {
      margin-bottom: 0;
      font-size: 1rem;
      font-weight: 600;
      color: #292d42;
    }

    button, a {
      margin-top: 0;
      margin-right: 0;
    }
  }

  .ContentBody {
    padding: 10px 20px;
    background-color: #f8fafc;

    .FormFooter {
      display: flex;
      justify-content: flex-end;

      .ant-btn:hover, .ant-btn:focus {
        color: $orange;
        background-color: #fff;
        border-color: $orange;
      }
    }
  }

  &Manager {
    margin-top: 115px;
  }
}

.PlusPrice {
  margin-left: 10px;
  color: #7C7C7C;
}

.itm_adj_prod {
  box-shadow: 0 0 4px 0 #00000038;
  margin: 0 5px;
}

.PartnerPage {
  padding-top: 60px;
  display: flex;
  width: 100%;
  padding-bottom: 40px;


  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $orange;
    border-color: $orange;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: $orange;
  }

  .ant-pagination {
    .ant-pagination-item-active {
      border-color: $red;
    }
  }

  .ant-tabs-nav .ant-tabs-tab-active {
    color: $red;
  }

  .ant-tabs-nav .ant-tabs-tab:hover {
    color: $red;
  }

  .ant-tabs-ink-bar {
    background-color: $red;
  }

  .ant-input-number:hover {
    border-color: $orange;
  }

  .ant-col.ant-form-item-label {
    line-height: 18px;
    margin-top: 14px;

    > label {
      margin-bottom: 0;
    }
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-input-number-focused {
    -webkit-box-shadow: 0 0 0 2px rgba(245, 134, 52, 0.2);
    -moz-box-shadow: 0 0 0 2px rgba(245, 134, 52, 0.2);
    box-shadow: 0 0 0 2px rgba(245, 134, 52, 0.2);
  }

  .ContentBody {
    .react-tabs__tab-list {
      display: flex;
    }

    .react-tabs__tab {
      width: 40%;
      text-align: center;
      font-weight: bold;
      font-size: 18px;
    }
  }

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: rgba(245, 134, 52, 0.1);
  }

  .ant-table-tbody {
    a {
      color: $red;
    }

    button.ant-btn-link {
      color: $red;
    }
  }

  .FieldRow {
    display: flex;
    flex-direction: row;
  }

  .FieldAction {
    flex: 0 0 25px;
  }

  .FieldWidget {
    flex: 1;
  }

  .PlusPrice {
    margin-left: 10px;
    color: #7C7C7C;
  }

  .Flex50 {
    flex: 1 1;
    margin-left: 5px;
  }

  .btn_not_now {
    color: #1890ff;
    margin-right: 10px;
    padding: 7.7px 17px;
    font-size: 12px;
    border: solid 1px;
    text-decoration: none;
  }

  .bg-yellow {
    background: #fffcd8;
  }

  .FieldSet {
    background: #f5f7fa;
    border: 1px solid #e3e7ee;
    border-radius: 10px;
    margin: 10px 0;
    padding: 10px 20px 15px;

    &::after {
      position: absolute;
      content: attr(data-title);
      font-size: 0.8125rem;
      left: 40px;
      top: 0;
      text-transform: uppercase;
      font-weight: 600;
      color: #4c5267bf;
      margin: 0 0 5px -5px;
      padding: 0 5px;
      background: linear-gradient(to bottom, #ffffff 0%, #ffffff 50%, #000000 50%, #f5f7fa 50%, #f5f7fa 100%);
      text-align: center;
    }

    .flex-d {
      align-items: flex-end;

      > .GlobalInput {
        width: 100%;

      }

      button {
        margin-bottom: 4px;
      }
    }
  }

  .AntRequired {
    label::before {
      display: inline-block;
      margin-right: 4px;
      color: #f5222d;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: '*';
    }
  }


  .ant-input-number, .ant-time-picker {
    width: 100%;
  }

  .ant-layout-sider-trigger {
    position: relative;
    font-size: 14px;
    background: $red;
    display: none;
    color: #FFFFFF;
    border: 1px solid $red;
    font-weight: 600;
    margin-left: auto;
    width: auto !important;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    height: auto !important;
    padding: 8px 5px;
    line-height: 16px;

    &:hover {
      background-color: #fff;
      color: $red;
    }
  }

  .ant-menu {
    color: #ffffff;
  }

  .ant-layout-sider {
    margin-top: 15px;
    max-width: unset !important;
    width: 100% !important;
    height: 100%;
    background-color: transparent;
    padding-left: 15px;
    padding-right: 15px;

    .ant-layout-sider-children {
      display: flex;
      height: calc(100vh - 20px);
      overflow-y: auto;
      flex-direction: column;
      overflow-x: hidden;
      margin-bottom: 30px;
    }

    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: #979797;
      color: #ffffff;
    }

    ul {
      padding-bottom: 14px;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      border-radius: 8px;

      li {
        padding-left: 18px !important;
        padding-right: 18px;
        margin-top: 0;
        margin-bottom: 0 !important;
        height: auto !important;
        line-height: 35px;
        color: #000;
        border-bottom: solid 1px silver;
        font-weight: 600;

        &:first-child {
          border-bottom: none;
        }

        &.start_stop_tacking {
          background: #ed3237;
          color: #FFFFFF;
          margin: 5px 0;
          margin-bottom: 5px !important;
          border-radius: 5px;
        }
      }

      &.ant-menu-inline-collapsed {
        width: 50px;
        padding-left: 0;
        padding-right: 0;

        //> .ant-menu-item {
        //  padding-left: 16px !important;
        //  padding-right: 16px !important;
        //}
      }
    }

  }

  .ant-layout {
    background-color: white;
    padding-bottom: 50px;
  }

  .partnerContent {
    padding-left: 40px;
    padding-right: 40px;
    width: calc(100% - 250px);
    min-height: 450px;
  }

  #breadcrumb {
    position: relative;
    display: inline-block;

    ul {
      display: flex;
      align-items: flex-end;

      li {
        position: relative;

        a {
          color: rgba(0, 0, 0, 0.45);
          font-size: 14px;
          -webkit-transition: 0.3s color;
          -moz-transition: 0.3s color;
          -ms-transition: 0.3s color;
          -o-transition: 0.3s color;
          transition: 0.3s color;

          &:hover {
            color: $red;
          }
        }

        &:last-of-type {
          color: rgba(0, 0, 0, 0.65);
          font-size: 14px;

        }

        &:not(:last-of-type) {
          margin-right: 18px;

          &::after {
            content: '/';
            position: absolute;
            right: -12px;
            top: 50%;
            color: rgba(0, 0, 0, 0.45);
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);
          }
        }
      }
    }

    &::after {
      content: '';
      position: absolute;
      width: calc(100% + 20px);
      height: 1px;
      background-color: rgba(0, 0, 0, 0.08);
      left: 0;
      bottom: -15px;
    }
  }

  .partnerMenu {
    max-width: 220px;

    min-width: 250px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 35px;
    //background-color: #FCFCFC;
    height: calc(100% + 160px);

    .categoryUl {
      padding: 14px 18px 14px 20px;
      background: #F3F3F3;
      border-radius: 8px;

      li {
        margin: 3px 0;
        line-height: 28px;

        a {
          display: flex;
          align-items: center;
          font-size: 14px;
          //display: block;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 100%;
          color: rgba(0, 0, 0, 0.65);

          i {
            margin-right: 10px;
            font-size: 16px;
          }

          &.active {
            color: $red;
          }

          &:hover {
            color: $red;
          }
        }
      }
    }
  }

  .toggleClassLi {
    text-align: right;

    img {
      width: 20px;
      cursor: pointer;
      margin-top: 0px;
    }
  }

  .AddNewButton {
    background: #ED3237;
    border-radius: 8px;
    max-width: 258px;
    //width: 100%;
    display: flex;
    align-items: center;
    margin: 20px auto 0;
    //display: block;
    font-size: 15px;
    color: #FFFFFF;
    letter-spacing: 0;
    font-weight: 500;
    border: 1px solid $red;
    padding: 3px 10px;
    -webkit-transition: .3s all;
    -moz-transition: .3s all;
    -ms-transition: .3s all;
    -o-transition: .3s all;
    transition: .3s all;

    &:hover {
      background-color: #fff;
      color: $red;
      border: 1px solid $red;
    }
  }

}

.rbc-day-slot > .rbc-timeslot-group .rbc-time-slot:first-child {
  border-bottom: 1px dashed #e7e7e7;
}

.rbc-day-slot .rbc-events-container {
  margin-right: 0px !important;
}

.rbc-row-bg, .rbc-row-content {
  display: none;
}

.rbc-header {
  border-bottom: none !important;
}


.gridable {
  align-items: start;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 15px;
  grid-row-gap: 5px;
  max-height: 320px;
  overflow: auto;

  -webkit-box-shadow: $modalShadow;
  -moz-box-shadow: $modalShadow;
  box-shadow: $modalShadow;
  padding: 10px;
  margin-bottom: 30px;
}


.itemDiv.itemDivSelected {
  background-color: #f6f6f673;
  -webkit-box-shadow: $modalShadow;
  -moz-box-shadow: $modalShadow;
  box-shadow: $modalShadow;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
  transform: translateY(0px);
}

.preparing-duration-container {
  padding: 15px;
  .preparing-duration-buttons {
    display: flex;
    margin-bottom: 10px;

    & > label:first-child {
      width: 132px;
      margin-right: 5px;
    }

    & > label:not(:first-child) {
      width: 90px;
      text-align: center;
    }
  }
}

.delay-container {
  .delay-buttons {
    display: flex;
    margin-bottom: 10px;

    & > label:first-child {
      width: 110px;
      margin-right: 5px;
    }

    & > label:not(:first-child) {
      width: 90px;
      text-align: center;
    }
  }
}


.itemDiv {
  border: 1px solid $checkboxBorder;
  //margin-top: 15px;
  //margin-bottom: 15px;
  position: relative;
  padding: 15px;
  cursor: pointer;
  -webkit-transition: 0.3s all;
  -moz-transition: 0.3s all;
  -ms-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
  -webkit-transform: translateY(5px);
  -moz-transform: translateY(5px);
  -ms-transform: translateY(5px);
  -o-transform: translateY(5px);
  transform: translateY(5px);
  margin-bottom: 10px;

  .removable {
    padding-left: 10px;
    color: $primaryColor2;
  }

  p {
    margin-bottom: 0;

    &.productName {
      color: $primaryColor1;
      font-size: 18px;
      margin-bottom: 5px;
    }
  }

  .plus-price {
    margin-left: 3px;
    color: #7C7C7C;
    font-size: 9px;
    //vertical-align: top;
  }

}

.ContentManager {
  @media screen and (min-width: 1200px) {
    width: calc(100% - 30px);
    margin-left: auto;
    margin-right: auto;
  }

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: rgba(245, 134, 52, 0.1);
  }

  .ant-pagination-item.ant-pagination-item-1.ant-pagination-item-active {
    border-color: $red;
  }

  .AddNewButton {
    background: #ED3237;
    border-radius: 8px;
    max-width: 258px;
    //width: 100%;
    display: flex;
    align-items: center;
    margin: 20px auto 0;
    //display: block;
    font-size: 15px;
    color: #FFFFFF;
    letter-spacing: 0;
    font-weight: 500;
    border: 1px solid $red;
    padding: 3px 10px;
    -webkit-transition: .3s all;
    -moz-transition: .3s all;
    -ms-transition: .3s all;
    -o-transition: .3s all;
    transition: .3s all;

    &:hover {
      background-color: #fff;
      color: $red;
      border: 1px solid $red;
    }
  }
}

.ingredientSubFields {
  //max-width: fit-content;
  display: flex;

  > :first-child {
    align-items: center;
    margin-top: 52px;
  }

  > :not(:first-child) {
    width: 100%;
    padding-left: 10px;
  }
}

#separated_form_div {
  background-color: #b3b3b30d;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  border: solid 1px #dcdcdc;
  display: contents;
}

.basic_form_description {
  height: 100px !important;
}

.ant-picker {
  width: 100%;
  margin-right: 10px !important;

  .ant-picker-suffix {
    margin-bottom: 5px;
  }

  .ant-picker-clear {
    .anticon-close-circle {
      margin-bottom: 7px;
    }
  }
}

.scheduled_date_input {
  display: flex;
}

#dropdown-basic {
  padding: 3px 10px;
  border-radius: 8px;
  margin-left: 10px;
}


.separateTitle {
  position: relative;
  margin-bottom: 30px;

  > .FieldSet {
    &::after {
      top: -10px;
    }
  }

  .ant-select-selector {
    height: 42px !important;
    padding-top: 3px !important;
  }
}

.ant-row {
  display: contents !important;
}

.ant-input-number-input-wrap {
  input {
    margin: 0 !important;
    border: none !important;
  }
}

#addCategoryForm {
  button {
    margin-top: 25px;
  }
}

.sidebar-custom {
  position: fixed;
  background: #FFFFFF;
  left: 0;
  top: 0;
  box-shadow: 5px 9px 10px 2px #0000001f;
  width: 250px;
  transform: translate(-260px, 0px);
  height: 100vh;

  &.active {
    transform: translate(0px, 0px);
  }

  .logout {
    //position: relative;
    font-size: 14px;
    background: #ED3237;
    /* display: none; */
    color: #FFFFFF;
    border: 1px solid #ED3237;
    font-weight: 600;
    margin-left: auto;
    border-radius: 8px;
    height: auto !important;
    padding: 8px 5px;
    width: 100%;
    line-height: 16px;
    display: block;
    text-align: center;
  }
}
.burger-menu{


}
.burger-menu-footer{
  margin-top: auto;
}

